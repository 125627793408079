import './index.css'
import CustomButton from '../CustomButton'

function PaymentPaywall({name,description,frequency,price,currency,isSelected,isSubscribed,selectOption,elementIndex}) {
  return (
    <div className={`payment-wall ${isSubscribed?'subscribe':''}`} onClick={()=>selectOption(elementIndex)}>
        <div className={`payment-heading ${isSubscribed?'subscribe':''}`}>
            <h1>{name}</h1>
            {isSubscribed && <i className="fa-solid fa-check subscribe"></i>}
            {!isSelected && !isSubscribed && <i className="fa-regular fa-circle payment"></i>}
        </div>
        <div className='payment-wall-content'>
        {isSubscribed && <div className='subscribed'><p>Subscribed</p></div>}
        <h1><span>{currency} {price}</span> {frequency}</h1>
        <h4>{description}</h4>
        {isSelected && !isSubscribed && <CustomButton customClassName='primary-button invite'>Subscribe</CustomButton>}
        </div>
    </div>
  )
}

export default PaymentPaywall