import Apihelper from './ApiHelper';
import axios from "axios";

export default class ConferenceServices {
    static getConferenceData=async (conferenceType,currentUserID,getIdTokenClaims,hootconfig)=>{
        try{
        var fulltoken = await getIdTokenClaims();
        var idtoken = fulltoken.__raw;
        //console.log(idtoken);
    // console.log(currentUser.user_uuid);
    const url =conferenceType==='lastjoined'? Apihelper.getLastJoinedConferenceURL(currentUserID): Apihelper.getInvitedConferenceURL(currentUserID);
    // console.log('Stream URL is: ',url);
    const res = await axios.get(url,
      {
        headers: {
          'Authorization': idtoken,
          'X-HOOT-Instance': hootconfig.name
        }
      }
      )
    .then(res => {
      // console.log('Got conference result',res.data);
      return res;
    //   setConferencelist(res.data.reverse());
    })
    .catch(error => {
      // console.log('Caught error while trying to fetch conferences: ',error);
      return null;
    });
    return res;
    }
    catch(error)
{
    // console.log('Unexpected error occured while trying to get conference streams',error);
}
return null;
}
static deleteconference = async(conferenceid,getIdTokenClaims,hootconfig)=> {
  let res={'message':'',result: false}
  try{
  var fulltoken = await getIdTokenClaims();
  var idtoken = fulltoken.__raw;
  var header = {
    "Authorization" : idtoken,
    "Content-Type": "application/json",
    'X-HOOT-Instance': hootconfig.name,
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "DELETE, GET, OPTIONS, PATCH, POST, PUT",
    "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept, Authorization"
  }
  const url = Apihelper.getDeleteConferenceUrl(conferenceid);  
  res =  await axios({method: 'delete', url: url, headers: header})
  .then(res => {
    return {'message':'',"result": true};
  })
  .catch(err => {
  //  console.log(err);
   return {'message':err.response.status===405?'Conference cannot be deleted. You are not an owner of this conference':err.response.data,"result": false}
  })
}
catch(error)
{
  res= {'message':'Unexpected error occured:'+error,"result": false}
}
return res
}
static createConference = async(confname,getIdTokenClaims,hootconfig,participantarr,owners,features)=> {
  const url = Apihelper.getCreateConferenceUrl(confname);
  // console.log('Got create conference URL',url)

  // console.log('Server name is: ',hootconfig.name)
  let res={}
  try{
  var fulltoken = await getIdTokenClaims();
  var header = {
    Authorization: fulltoken.__raw,
    'x-HOOT-Instance': hootconfig.name,
    "Content-Type": "application/json",
  };
  var body = {
    participants: participantarr,
    moderators: owners,
    features: features
  };
  // console.log('Conference body is: ',body)
  res = await axios
      .post(url, body, {withCredentials:false,
        headers: header,
      })
      .then((res) => {
       // console.log(res)
        if(res.status===200)
       {
        return {'message':'',"result": true};
       }
       else
       if(res.status===201)
       {
        return {'message': 'This conference already exists!',"result": false}
       }
      })
    .catch((err) => {
      return {'message': 'Got error response while creating conference: '+err,"result":false}
    });
}
catch(error)
{
  res={'message': 'Unexpected error occured while creating conference: '+error,"result":false}
}
return res
}
static getSIPDetails=async (token,uuid,hootconfig,getIdTokenClaims)=>{
  let res={}
  try{
  var fulltoken = await getIdTokenClaims();
  var idtoken = fulltoken.__raw;
  var header = {
   "Authorization" : idtoken,
   "Content-Type": "application/json",
   'X-HOOT-Instance': hootconfig.name,
   }
   const url = Apihelper.getSIPDetailsURL(token, uuid)
   res =  await axios({method: 'get', url: url, headers: header})
   .then(res => {
    //  console.log(res.data);
    return {'username': res.data.username,'password': res.data.secret,'domain':res.data.domain,'protocol': res.data.protocol,'result':true}
    })
   .catch(err => {
    return {
      'result': false,
      'message': 'Unexpected error occured while trying fetch SIP details:'+err
    }
   })
  }
  catch(error)
  {
    res={'message':'Unexpected error occured while trying fetch SIP details:'+error,'result':false}
  }
   return res
}

static getFavouriteConferences=async(getIdTokenClaims,uuid,hootconfig)=>{
    let res=null
    try{
    var fulltoken = await getIdTokenClaims();
    var idtoken = fulltoken.__raw;
    const url = Apihelper.getFavouriteConferenceURL(uuid);
    res = await axios
      .get(url, {
        headers: { Authorization: idtoken,
          'x-HOOT-Instance': hootconfig.name},
      })
      .then((res) => {
        // res.data.map((item) => favouriteset.push(item.conference.name));
        //console.log(favouriteset)
        return {'result':true,'conference':res.data}
        // dispatch(setfavourites(favouriteset));
      }
        )
        .catch((error) => {
          console.log(error.response.data)
          return {'result':false,'message': 'Unexpected error occurred while trying to fetch Favourite conferences '+error}
        });
      }
      catch(error)
      {
        res={'result':false,'message': 'Unexpected error occurred while trying to fetch Favourite conferences '+error}
      }
      return res
}

static getRecentSearchTerms=async(uuid,getIdTokenClaims,hootconfig)=>{
  let res=null
  try{
    var fulltoken = await getIdTokenClaims();
    var idtoken = fulltoken.__raw;
    const url = Apihelper.getSearchHistoryUrl(uuid);
    // console.log('Get recent search history url is: ',url)
    var header = {
      "Authorization" : idtoken,
      "Content-Type": "application/json",
      'X-HOOT-Instance': hootconfig.name,
      }
    res = await axios
      .get(url, {
        headers: header,
      })
      .then((res) => {
      //  console.log('got response for search terms: ',res.data,'and status code: ',res.status)
        return {'result':true,'data':res.data===null?[]:res.data}
      })
    .catch((err) => {
      // console.log('While fetching search terms error is',err)
      return {'message': 'Got an error while fetching search terms'+err,'result':false}
    });
  }
  catch(error)
  {
    // console.log('Getting unexpected error while fetching search terms: ',error)
    res={'message':'Unexpected error occured while getting search history: '+error,'result':false}
  }
  return res
}

static getSearchData=async(searchterm,getIdTokenClaims,hootconfig)=>{
  let res=null
  try{
    var fulltoken = await getIdTokenClaims();
    var idtoken = fulltoken.__raw;
    const url = Apihelper.getSearchConferenceURL(searchterm);
    // console.log('Get search conference url: ',url)
    var header = {
      "Authorization" : idtoken,
      "Content-Type": "application/json",
      'X-HOOT-Instance': hootconfig.name,
      }
    res = await axios
      .get(url, {
        headers: header,
      })
      .then((res) => {
      //  console.log('got response for search conference: ',res.data,'and status code: ',res.status)
        return {'result':true,'data':res.data===null?[]:res.data}
      })
    .catch((err) => {
      // console.log('While fetching search results got error: ',err)
      return {'message': 'While fetching search results got error: '+err,'result':false}
    });
  }
  catch(error)
  {
    // console.log('Getting unexpected error while fetching search results: ',error)
    res={'message':'Unexpected error occured while getting search results: '+error,'result':false}
  }
  return res
}

static addSearchTerm=async(uuid,searchstring,getIdTokenClaims,hootconfig)=>{
  let res=null
  try{
    var fulltoken = await getIdTokenClaims();
    var idtoken = fulltoken.__raw;
    const url = Apihelper.getAddSearchHistoryUrl(uuid,searchstring);
    // console.log('Got add search string conference url: ',url)
    var header = {
      "Authorization" : idtoken,
      'X-HOOT-Instance': hootconfig.name
      }

    res = await axios({method: 'put', url: url, headers: header})
      .then((res) => {
        return {'result':true}
      })
    .catch((err) => {
      // console.log('While adding search term got error: ',err)
      return {'message': 'While adding search term to history, got an error: '+err,'result':false}
    });
  }
  catch(error)
  {
    // console.log('Getting unexpected error while adding search term: ',error)
    res={'message':'Unexpected error occured while adding search term to history: '+error,'result':false}
  }
  return res
}

  static getConferenceUsers=async(confid,getIdTokenClaims,hootconfig)=>{
  let res=null
  try{
    var fulltoken = await getIdTokenClaims();
    var idtoken = fulltoken.__raw;
    const url = Apihelper.getConferenceUsersURL(confid);
    // console.log('Get search conference url: ',url)
    var header = {
      "Authorization" : idtoken,
      "Content-Type": "application/json",
      'X-HOOT-Instance': hootconfig.name,
      }
    res = await axios
      .get(url, {
        headers: header,
      })
      .then((res) => {
        return {'result':true,'data':res.data}
      })
    .catch((err) => {
      // console.log('While fetching search results got error: ',err)
      return {'message': 'While getting conference users data, got an error: '+err,'result':false}
    });
  }
  catch(error)
  {
    // console.log('Getting unexpected error while getting conference users data: ',error)
    res={'message':'Unexpected error occured while getting conference users data: '+error,'result':false}
  }
  return res
  }
  static editConference=async(confid,newParticipants,newModerators,removeParticipants,features,getIdTokenClaims,hootconfig)=>{
    const url = Apihelper.getEditConferenceURL(confid);
  // console.log('Got create conference URL',url)
  // console.log('Server name is: ',hootconfig.name)
  let res={}
  try{
  var fulltoken = await getIdTokenClaims();
  var header = {
    Authorization: fulltoken.__raw,
    'x-HOOT-Instance': hootconfig.name,
    "Content-Type": "application/json",
  };
  var body = {
    new_moderators: newModerators,
    new_participants: newParticipants,
    features: features,
    remove_users:removeParticipants 
  };
  res = await axios
      .post(url, body, {
        headers: header,
      })
      .then((res) => {
       // console.log(res)
        if(res.status===200)
       {
        return {'message':'',"result": true};
       }
      })
    .catch((err) => {
      return {'message': 'Got error response while editing conference: '+err,"result":false}
    });
}
catch(error)
{
  res={'message': 'Unexpected error occured while editing conference: '+error,"result":false}
}
return res
  }

  static addFavouriteConference=async (uuid,conferenceId,getIdTokenClaims,hootconfig)=>{
    let res=false
    try{
      var fulltoken = await getIdTokenClaims();
      var idtoken = fulltoken.__raw;
      var header = {
        "Authorization" : idtoken,
        "Content-Type": "application/json",
        'X-HOOT-Instance': hootconfig.name,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "DELETE, GET, OPTIONS, PATCH, POST, PUT",
        "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept, Authorization"
      }
      const url = Apihelper.getSetFavouriteUrl(uuid,conferenceId);
      // console.log(url)
      // console.log(idtoken);
      res =  await axios({method: 'post', url: url, headers: header})
      .then(res => {
      //console.log(res)
      return true
      })
      .catch(err => {
      // setError(err.response.data);
      // setShowerror(true);
      console.log('Got error while adding conference to favourites: ',err)
      return false
      })
    }
    catch(error){
      console.log('Unexpected error while adding conference to favourites: ',error)
    }
    return res
  }

  static removeFavouriteConference=async (uuid,conferenceId,getIdTokenClaims,hootconfig)=>{
    let res=false
    try{
      var fulltoken = await getIdTokenClaims();
      var idtoken = fulltoken.__raw;
      var header = {
        "Authorization" : idtoken,
        "Content-Type": "application/json",
        'X-HOOT-Instance': hootconfig.name,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "DELETE, GET, OPTIONS, PATCH, POST, PUT",
        "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept, Authorization"
      }
      const url = Apihelper.getUnSetFavouriteUrl(uuid,conferenceId);
      // console.log(url)
      // console.log(idtoken);
      res =  await axios({method: 'post', url: url, headers: header})
      .then(res => {
      //console.log(res)
      return true
      })
      .catch(err => {
      // setError(err.response.data);
      // setShowerror(true);
      console.log('Got error while adding conference to favourites: ',err)
      return false
      })
    }
    catch(error){
      console.log('Unexpected error while adding conference to favourites: ',error)
    }
    return res
  }

  static addSubscription=async (conferenceId,getIdTokenClaims,hootconfig)=>{
    let res=null
    try
    {
      const url=Apihelper.getSubscribeConfURL(conferenceId)
      const body={"api_endpoint": Apihelper.subEndpoint}
      var fulltoken = await getIdTokenClaims();
      var header = {
        Authorization: fulltoken.__raw,
        'x-HOOT-Instance': hootconfig.name
      };
      // console.log('Conference body is: ',body)
      res = await axios
          .post(url, body, {withCredentials:false,
            headers: header,
          })
          .then((res) => {
           // console.log(res)
            if(res.status===201)
           {
            return {'subscriptionID': res.data.id,"result": true,'conferenceNumber': res.data.conference_number};
           }
          })
        .catch((err) => {
          return {'message': 'Got error response while subscribing to conference: '+err,"result":false}
        });
    }
    catch(e)
    {
      res={'message': 'Unexpected error occured while subscribing to conference: '+e,"result":false}
    }
    return res
  }

  static deleteSubscription=async (subId,getIdTokenClaims,hootconfig)=>{
    let res=null
    try{
      const url=Apihelper.getUnsubscribeConfURL(subId)
      var fulltoken = await getIdTokenClaims();
      var idtoken = fulltoken.__raw;
      var header = {
      "Authorization" : idtoken,
      'X-HOOT-Instance': hootconfig.name,
      }
      res =  await axios({method: 'delete', url: url, headers: header})
      .then(res => {
       return {"result": true};
       })
      .catch(err => {
        //  console.log(err);
      return {'message':'Subscription not found',"result": false}
      })
      }
    catch(e)
    {
      res= {'message':'Unexpected error occured while trying to delete subscription'+e,"result": false}
    }
    return res
  }
}