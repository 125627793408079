import React from 'react';
import LazyLoad from 'react-lazy-load';
import './index.css'

const LazyImageLoader = (props) => (
  <div>
    <LazyLoad height={props.height}>
      <img className={props.customClassName} src={props.imageSource} alt={props.imgAlt} />
    </LazyLoad>
  </div>
)

export default LazyImageLoader