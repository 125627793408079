const initialState = {
    conferences: []
  };

const pinnedConferenceReducer=(state=initialState,action)=>{
    switch (action.type) {
        case 'ADD_CONFERENCE':
          return {
            ...state,
            conferences: [...state.conferences, action.payload],
          };
        case 'REMOVE_CONFERENCE':
          return {
            ...state,
            conferences: state.conferences.filter(
              (conference) => conference.id !== action.payload
            ),
          };
        case 'MOVE_CONFERENCE':
            const conferenceToMoveIndex = state.conferences.findIndex(
              conference => conference.id === action.payload
            );
            if (conferenceToMoveIndex !== -1) {
              const updatedConferences = [...state.conferences];
              const conferenceToMove = updatedConferences.splice(
                conferenceToMoveIndex,
                1
              )[0];
              updatedConferences.unshift(conferenceToMove);
              return {
                ...state,
                conferences: updatedConferences
              };
            }
          default:
          return state;
      }
}

export default pinnedConferenceReducer;