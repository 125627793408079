import Navbar from '../../components/Navbar';
import './index.css';
import LoadingOverlay from 'react-loading-overlay-ts';
import React from 'react'
import {useState} from 'react';
import PinnedConferenceModal from '../../components/PinnedConferenceModal';
import PaymentPaywall from '../../components/PaymentPaywall';

function Subscriptions() {
  const [isLoading, setisLoading] = useState(false);
  const [pinnedConference, setpinnedConference] = useState(false)
  const closePinnedConference=()=>{
    setpinnedConference(false)
  }

  const [selectedindex, setindex] = useState(-1)

  const showPinnedConference=()=>{
    setpinnedConference(true)
  }

  const changeIsLoading=(value)=>{
    setisLoading(value);
    // console.log('Value of isloading in search screen is',isDataLoading)
  }

  const subscriptions=[
    {
    name: 'Hoot Pro Monthly',
    price: 300,
    currency: 'INR',
    frequency: 'per month',
    description: 'Includes unlimited conference hosting and document uploads.',
    isSelected: false,
    isSubscribed:false
  },
  {
    name: 'Hoot Pro Yearly',
    price: 3000,
    currency: 'INR',
    frequency: 'per year',
    description: 'Includes unlimited conference hosting and document uploads.',
    isSelected: false,
    isSubscribed:false
  },
]

  const selectOption=(index)=>{
    setindex(index)
  }

  const [alert, setAlert] = useState(null);

  const closeModal=()=>{
    setAlert(null);
  }

  const showAlert=(type,heading,message)=>{
    setAlert({
      message: message,
      type: type,
      heading: heading
    });
    setTimeout(()=>{
      setAlert(null)
    },1500);
  }

  return (
    <LoadingOverlay
    active={isLoading}
    spinner>
    <div className="subscriptions">
      <Navbar changeIsLoading={changeIsLoading} showAlert={showAlert} closeAlert={closeModal} showPinnedConferences={showPinnedConference}/>
      <div className='subscription-content'>
      {pinnedConference && <PinnedConferenceModal closeModal={closePinnedConference}/>}
      <div className="favourite-nav-row">
          <a href="/">HooT.MX Home</a>
          <p> &nbsp;  &#62; &nbsp;   Subscriptions</p>
        </div>
        <h4>Subscriptions</h4>
        <div className='subscriptions'>
        {subscriptions.map((sub,index) => <PaymentPaywall key={index} name={sub.name} currency={sub.currency} frequency={sub.frequency} price={sub.price} isSelected={selectedindex===index} isSubscribed={sub.isSubscribed} description={sub.description} selectOption={selectOption} elementIndex={index}/>
        )}
        </div>
      </div>
    </div>
    </LoadingOverlay>
  );
}

export default Subscriptions;
