import "./App.css";
import { useDispatch,useSelector } from "react-redux";
import { setConfigDetails } from "./stateManagement/reducer/HootConfig";
import Apihelper from "./services/ApiHelper";
import AppRoutes from "./routes";
// import { getBrowserToken,messaging,getConferenceIdByTopicId} from "./util/Firebase";
import { useEffect } from "react";
// import {moveConference, receiveMessage} from './stateManagement/actions'
// import {onMessage } from "firebase/messaging";
import speakerbusIcon from "./assets/sbIconTitle.png"


function App(props) {
  const dispatch = useDispatch();
  dispatch(setConfigDetails(props.config));
  Apihelper.setbaseUrl(props.config.app.api.base_url); 
  const subscriptions = useSelector((state) => state.subscriptions)
  useEffect(() => {
    if(props.config.name==='Speakerbus')
    {
      document.title = "Speakerbus-CHEIMS";
      const linkElement = document.head.querySelector("link[rel='icon']");
      linkElement.href = speakerbusIcon;
    }
    // getBrowserToken()
    // if ('serviceWorker' in navigator) {
    //   navigator.serviceWorker.register('/firebase-messaging-sw.js')
    //     .then(registration => {
    //       registration.addEventListener('message', event => {
    //         if (event.data && event.data.type === 'DATA') {
    //           const payload = event.data.payload;
    //           // Dispatch the payload to the Redux store
    //           dispatch(receiveMessage(payload.data));
    //           let conferenceId=getConferenceIdByTopicId(subscriptions.subscriptions,payload.data.conference_number)
    //           if(conferenceId!=null)
    //           dispatch(moveConference(conferenceId))
    //         }
    //       });
    //     })
    //     .catch(error => {
    //       console.log('Service Worker registration failed:', error);
    //     });
    // }
    // onMessage(messaging, (payload) => {
    //   console.log('Message received. ', payload);
    //   dispatch(receiveMessage(payload.data));
    //   let conferenceId=getConferenceIdByTopicId(subscriptions.subscriptions,payload.data.conference_number)
    //   if(conferenceId!=null)
    //   dispatch(moveConference(conferenceId))
    //   // ...
    // });
  }, [dispatch]);
  // messaging.onMessage((payload) => {
  //   // Dispatch an action to handle the received message
  //   dispatch(receiveMessage(payload));
  // });
  const setUserDetails=(userDetails)=>{
    dispatch(setUserDetails(userDetails))
  }
  return (
    // <div className={isAuthenticated ? 'App' : 'App2'}>
    <div>
      <AppRoutes />
    </div>
  );
}


export default App;
