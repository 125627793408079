import './index.css'

function SearchBarInput(props) {
  return (
    <div className="search-bar-input">
    <input type="text" value={props.inputValue} onChange={props.changeValue} placeholder=" Search for Last Joined, Invited and more" onKeyUp={props.addValue}></input>
    <i className="fa-solid fa-magnifying-glass"></i>
    </div>
  )
}

export default SearchBarInput
