import './index.css';

function DeleteButton(props) {
  // const closeModal=()=>setshowModal(false)
  return (
    <div className='delete-button'>
      <i className="fa-solid fa-trash" onClick={(event)=>{
        console.log('Clicked delete button')
        props.deleteFunction(props.conferenceID,event)}}></i>
      {/* {showModal && <DeleteModal closeModal={closeModal}/>} */}
    </div>
  )
}

export default DeleteButton