import React, { useState, useEffect } from "react";
import photo from "../../assets/hootlogo.png";
import chiemslogo from '../../assets/speakerbus_logo.svg'
import "./index.css";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector } from "react-redux";
import Features from "./Components/Features";
import CustomButton from "../../components/CustomButton";
import LoadingOverlay from 'react-loading-overlay-ts';
import AccessCode from '../../components/AccessCode';
import LocalStorage from '../../util/LocalStorage';
import Modal from '../../components/AlertDialog/index';

function LoginPage() {
  const config = useSelector((state) => state.hootConfig);
  const [accessCodeVerified,setAccessCode]=useState(!config.app.settings.access_code_enabled);
  const [isDataLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState(null);
  const showAlert=(type,heading,message)=>{
    setAlert({
      message: message,
      type: type,
      heading: heading
    });
    setTimeout(()=>{
      setAlert(null)
    },1500);
  }
  const closeModal=()=>{
    setAlert(null);
  }
  useEffect(()=>{
    // console.log('Config is: ',config)
    // console.log('Checking for an access code');
    const storeAccessCode=LocalStorage.getLocalStorageValue('accessCode');
    const storeInstance=LocalStorage.getLocalStorageValue('instance');
    if(!config.app.settings.access_code_enabled)
    {
      // console.log('Access code is not enabled');
      return;
    }
    if(storeAccessCode==='' || storeInstance==='')
      setAccessCode(false);
    else if (storeAccessCode!=='' && storeInstance===config.name)
      setAccessCode(true);
    // console.log('Access code value is',accessCodeVerified)
  },[config.app.settings.access_code_enabled,config.name,accessCodeVerified]);
  const changeAccessCodeValue=(value)=>{
   setAccessCode(value);
  }
  const setIsLoadingFalse=()=>{
    setIsLoading(false);
  }
  const setIsLoadingTrue=()=>{
    setIsLoading(true);
  }
  const { loginWithRedirect } = useAuth0();
  return (
    <LoadingOverlay
    active={isDataLoading}
    spinner
  >
    <div className={config.name==="Generic"?"login-screen-full":"login-screen-full secondary"}>
    <div className={config.name==="Generic"?"login-screen":"login-screen secondary"}>
    <Modal toggleModal={showAlert} alert={alert} closeModal={closeModal}/>
      <div className="hoot-image"> 
      <img className={config.name==="Generic"?"hootlogo":'hootlogo secondary'} src={config.name==="Generic"?photo:chiemslogo} alt="hoot-logo"/>
      </div>
      <div className = {config.name==="Generic"?"vertical-divider":"vertical-divider secondary"}></div>
      <div className='feature-tab'>
      <h4 className={config.name==="Generic"?"heading":"heading secondary"}>
      The World is a Conference! <br></br> Collaboration for Everyone!
    </h4>
        <div className="features">
        <Features></Features>
        {/* {!config.app.settings.access_code_enabled &&<LoginButton/> } */}
        {accessCodeVerified &&<CustomButton customClassName={config.name==="Generic"?"primary-button":"primary-button chiems"} customFunction={() => loginWithRedirect()}>Get Started</CustomButton> }
        {!accessCodeVerified && <AccessCode changeIsLoadingFalse={setIsLoadingFalse} changeIsLoadingTrue={setIsLoadingTrue} changeAccessCode={changeAccessCodeValue} showAlert={showAlert}/>}
        {!accessCodeVerified &&<CustomButton customClassName={"secondary-button"} customFunction={() => window.location.href = "mailto:info@mpsc.io?subject=%5BHooT%5D%20Access%20Code%20Request&body=I%20am%20interested%20in%20exploring%20the%20HooT%20Conferencing%20App.%20%0A%0A"}>Get Access Code</CustomButton> }
        </div>
    </div> 
    </div>
    </div>
    </LoadingOverlay>
  );
}

export default LoginPage;