export const setConfigDetails = (config) => {
    //console.log(config);
    return {
      type: "SET_CONFIG_DETAILS",
      payload: config,
    };
  };
  
  function configReducer(config = {}, action) {
    switch (action.type) {
      case "SET_CONFIG_DETAILS":
        return {
          ...action.payload,
        };
  
      default:
        return config;
    }
  }
  
  export default configReducer;
  