import { combineReducers } from "redux";
import favouritesReducer from "./reducer/Favourites";
import configReducer from "./reducer/HootConfig";
import userReducer from "./reducer/UserDetails";
import conferenceReducer from './reducer/CurrentConference';
import pinnedConferenceReducer from "./reducer/PinnedConferences";
import subscriptionReducer from './reducer/Subscriptions';
import fcmReducer from "./reducer/FirebaseEvents";

const rootReducer = combineReducers({
  user: userReducer,
  favourites: favouritesReducer,
  hootConfig: configReducer,
  conference: conferenceReducer,
  pinnedConferences: pinnedConferenceReducer,
  subscriptions: subscriptionReducer,
  fcmReducer: fcmReducer
});

export default rootReducer;
