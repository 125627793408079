import './index.css'

const RecentSearchButton=(props)=> {
  return (
    <button className="recent-search-button" onClick={props.customFunction}>
      {props.children}
    </button>
  )
}

export default RecentSearchButton
