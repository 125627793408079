// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root\r\n{\r\n    width: 75px;\r\n    border: solid 0.5px #D0D5DD;\r\n    border-radius: 8px;\r\n    height: 40px;\r\n    color: #101828;\r\n    font-size: 1em;\r\n    font-family: 'Inter', sans-serif;\r\n    font-weight: 400;\r\n}", "",{"version":3,"sources":["webpack://./src/components/MaterialTimePicker/index.css"],"names":[],"mappings":"AAEA;;IAEI,WAAW;IACX,2BAA2B;IAC3B,kBAAkB;IAClB,YAAY;IACZ,cAAc;IACd,cAAc;IACd,gCAAgC;IAChC,gBAAgB;AACpB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap');\r\n\r\n.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root\r\n{\r\n    width: 75px;\r\n    border: solid 0.5px #D0D5DD;\r\n    border-radius: 8px;\r\n    height: 40px;\r\n    color: #101828;\r\n    font-size: 1em;\r\n    font-family: 'Inter', sans-serif;\r\n    font-weight: 400;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
