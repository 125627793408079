import Navbar from '../../components/Navbar';
import './index.css';
import LoadingOverlay from 'react-loading-overlay-ts';
import React from 'react'
import {useState} from 'react';
import Modal from '../../components/AlertDialog'
import ConferenceModal from '../../components/ConferenceModal'
import SessionStorage from '../../util/SessionStorage';
import { useAuth0 } from "@auth0/auth0-react";
import ConferenceGridView from '../../components/ConferenceGridView'
import ConferenceServices from '../../services/ConferenceServices';
import { useNavigate } from "react-router-dom";
import {setConferenceAction} from "../../stateManagement/actions/index"
import { useSelector,useDispatch } from "react-redux";
import InvitePickerAdvanced from '../../components/InvitePickerAdvanced';
import PinnedConferenceModal from '../../components/PinnedConferenceModal';

function Favourites() {
  const [isDataLoading, setisDataLoading] = useState(false);

  // const [modalOverlay, setModalOverlay] = useState(false);

  const [isFavourite,setFavourite] = useState(false)

  const [alert, setAlert] = useState(null);

  const [conference,setConference] = useState(null)

  const {getIdTokenClaims,isLoading,user} = useAuth0();

  const hootconfig = useSelector((state) => state.hootConfig);

  const navigate =useNavigate()

  const dispatch = useDispatch()

  const [showInvite, setshowInvite] = useState(false)
  const [inviteConference, setinviteConference] = useState(null)
  const [inviteParticipantsList, setinviteParticipantsList] = useState([])

  const [pinnedConference, setpinnedConference] = useState(false)

  const closePinnedConference=()=>{
    setpinnedConference(false)
  }

  const showPinnedConference=()=>{
    setpinnedConference(true)
  }

  // console.log('User details are: ',userDetails)
  // console.log('User details are: ',user)

  const changeShowInvite=(event)=>{
    event.preventDefault()
    setshowInvite(!showInvite)
  }

  const showInvitePicker=async(conference,event)=>{
    event.stopPropagation()
    changeIsLoading(true)
    const res=await ConferenceServices.getConferenceUsers(conference.conference.id,getIdTokenClaims,hootconfig)
    changeIsLoading(false)
    if(res.result===false)
    showAlert('error','Unable to fetch conference users data',res.message)
    else
    {
    console.log('Got conference users data',res.data)
    const confParticipants=res.data.participants===null?[]:res.data.participants
    const confModerators=res.data.moderators===null?[]:res.data.moderators
    setinviteParticipantsList([...confModerators,...confParticipants])
    setinviteConference(conference)
    setshowInvite(true)
    }
  }

  const changeIsLoading=(value)=>{
    setisDataLoading(value);
  }

  const closeConferenceModal=()=>
  {
  console.log('Closing conference modal')
  setConference(null)
  }


  const showAlert=(type,heading,message)=>{
    setAlert({
      message: message,
      type: type,
      heading: heading
    });
    setTimeout(()=>{
      setAlert(null)
    },1500);
  }

  const closeModal=()=>{
    setAlert(null);
  }

  const deleteConference=async(conferenceid,event)=>{
    event.stopPropagation()
    console.log('Delete conference with id',conferenceid)
    setisDataLoading(true)
    const res=await ConferenceServices.deleteconference(conferenceid,getIdTokenClaims,hootconfig)
    setisDataLoading(false)
    if(res.result===true)
    {
    showAlert('success','Conference successfully deleted','You won\'t be able to view or use this conference anymore')
    // getSearchData()
  }
    else
    showAlert('error','Couldn\'t delete the conference',res.message)
  }

  const checkForFavourites = async(item,invokedConference)=>{
    setisDataLoading(true)
    console.log('Checking for favourites')
    const res=await ConferenceServices.getFavouriteConferences(getIdTokenClaims,SessionStorage.getSessionStorageValue('user_uuid'),hootconfig)
    if (res.result===false)
    {
      showAlert('error','Cannot show conference details',res.message)
    }
    else
    {
      setFavourite(false)
      const conferences=res.conference
      if(conferences!=null)
      for(let i=0;i<conferences.length;i++)
      {
        if (conferences[i].conference.name===item.conference.name)
        {
        console.log('Found conference in favorite')
        setFavourite(true)
        }
      }
      setConference(item)
      // setinvokedConference(invokedConference)
    }
    setisDataLoading(false)
  }

  const getConferenceUsers=async(conference,event)=>{
    event.stopPropagation()
    changeIsLoading(true)
    const res=await ConferenceServices.getConferenceUsers(conference.conference.id,getIdTokenClaims,hootconfig)
    changeIsLoading(false)
    if(res.result===false)
    showAlert('error','Unable to fetch conference users data',res.message)
    else
    {
    console.log('Got conference users data',res.data)
    console.log('Got conference features data: ',conference.conference.features)
    const conferenceData={
      'id': conference.conference.id,
      'moderators': res.data.moderators===null?[]:res.data.moderators,
      'participants': res.data.participants===null?[]:res.data.participants,
      'name': conference.conference.name,
      'isWebCamOn': Object.keys(conference.conference.features).length === 0?false:conference.conference.features.join_webcam_on,
      'isAudioOn': Object.keys(conference.conference.features).length === 0?false:conference.conference.features.join_audio_on,
      'documents': (Object.keys(conference.conference.features).length === 0 && Object.keys(conference.conference.features.documents).length === 0 ) ?[]:conference.conference.features.documents.pdf_list,
    }
    dispatch(setConferenceAction(conferenceData))
    navigate('/editconference')
    }
  }

  if (isLoading)
  {
    console.log('Auth0 initializing')
  return(
  <LoadingOverlay
    active={true}
    spinner>
      <div className="favourites">
      </div>
    </LoadingOverlay>
    )
  }
  else
  {
  return (
    <LoadingOverlay
    active={isDataLoading}
    spinner>
    <div className="favourite-screen">
      {/* <Navbar changeIsLoading={changeIsLoading}/> */}
      <Navbar changeIsLoading={changeIsLoading} showAlert={showAlert} showPinnedConferences={showPinnedConference}/>
      <div className="favourite-content">
      {conference && <ConferenceModal isFavourite={isFavourite} closeModalDisplay={closeConferenceModal} invokedConference={'invited'} item={conference} hootconfig={hootconfig} getIdTokenClaims={getIdTokenClaims} uuid={SessionStorage.getSessionStorageValue('user_uuid')}/>}
      {showInvite && <InvitePickerAdvanced participantList={inviteParticipantsList} userEmail={user.email} conference={inviteConference} changeShowInvite={changeShowInvite}/>}
      <Modal toggleModal={showAlert} alert={alert} closeModal={closeModal}/>
      {pinnedConference && <PinnedConferenceModal closeModal={closePinnedConference}/>}
        <div className={hootconfig.name==="Generic"?"favourite-nav-row":"favourite-nav-row chiems"}>
          <a href="/">HooT.MX Home</a>
          <p> &nbsp;  &#62; &nbsp;   Favorites</p>
        </div>
        <h4 className='favourite-content-heading'>Your Favorite Conferences</h4>
        <ConferenceGridView invokedConference={'favourite'} deleteFunction={deleteConference} checkForFavourites={checkForFavourites} getIdTokenClaims={getIdTokenClaims} getConferenceUsers={getConferenceUsers} showInvite={showInvitePicker}/>
      </div>
    </div>
    </LoadingOverlay>
  );
  }
}

export default Favourites;
