export const setConferenceAction = (conference) => {
    console.log(conference);
    return {
      type: "SET_CONFERENCE",
      payload: conference,
    };
}

export const addConference = (conference) => ({
  type: 'ADD_CONFERENCE',
  payload: conference,
});

export const removeConference = (conferenceId) => ({
  type: 'REMOVE_CONFERENCE',
  payload: conferenceId,
});

export const moveConference = (conference) => ({
  type: 'MOVE_CONFERENCE',
  payload: conference
});


export const addSubscription = (conferenceId,subscriptionId, topicId) => ({
  type: 'ADD_SUBSCRIPTION',
  payload: {
    conferenceId,
    subscriptionId,
    topicId
  }
});

export const removeSubscription = (conferenceId) => ({
  type: 'REMOVE_SUBSCRIPTION',
  payload: conferenceId
})

export const receiveMessage = (payload) => ({
  type: 'RECEIVE_MESSAGE',
  payload,
});