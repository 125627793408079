import './Features.css';
import { useSelector } from "react-redux";

const Features=()=>{
    const config = useSelector((state) => state.hootConfig);
    return(
        <table>
            <tbody>
        <tr>
            <td className="td-feature-image">
            {config.name==="Generic" && <img
          className="tick"
          src="https://cdn-icons-png.flaticon.com/512/443/443138.png" alt="tick-mark"/>
            }
            {config.name!="Generic" && <i className="fa-solid fa-check" style={{ fontSize: "24px",color: "#0D2D75" }}/>
            }
        </td>
        <td className={config.name==="Generic"?"td-feature-text":"td-feature-text secondary"}>Conferencing Tool for your team</td>
        </tr>
        <tr>
        <td className="td-feature-image">
        {config.name==="Generic" && <img
          className="tick"
          src="https://cdn-icons-png.flaticon.com/512/443/443138.png" alt="tick-mark"/>
            }
            {config.name!="Generic" && <i className="fa-solid fa-check" style={{ fontSize: "24px",color: "#0D2D75" }}/>
            }
        </td>
        <td className={config.name==="Generic"?"td-feature-text":"td-feature-text secondary"}>Highly Customizable Framework</td>
        </tr>
        <tr>
            <td className="td-feature-image">
            {config.name==="Generic" && <img
          className="tick"
          src="https://cdn-icons-png.flaticon.com/512/443/443138.png" alt="tick-mark"/>
            }
            {config.name!="Generic" && <i className="fa-solid fa-check" style={{ fontSize: "24px",color: "#0D2D75" }}/>
            }
            </td>
        <td className={config.name==="Generic"?"td-feature-text":"td-feature-text secondary"}>Cloud based collaboration tool</td>
        </tr>
        <tr>
            <td className="td-feature-image">
            {config.name==="Generic" && <img
          className="tick"
          src="https://cdn-icons-png.flaticon.com/512/443/443138.png" alt="tick-mark"/>
            }
            {config.name!="Generic" && <i className="fa-solid fa-check" style={{ fontSize: "24px",color: "#0D2D75" }}/>
            }
            </td>
        <td className={config.name==="Generic"?"td-feature-text":"td-feature-text secondary"}> Cloud Native</td>
        </tr>
        </tbody>
    </table>
    );
}

export default Features;