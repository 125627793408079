import React from 'react'
import LoginPage from './screens/Login/index';
import DashBoard from './screens/DashBoard/index';
import PrivateRoute from './util/PrivateRoute/index';
import Favourites from "./screens/Favourites";
import EditConference from './screens/EditConference';
import Resources from './screens/Resources';
import Subscriptions from "./screens/Subscriptions";
import SearchScreen from "./screens/SearchScreen";
import LastJoinedConference from "./screens/LastJoinedConference";
import InvitedConference from "./screens/InvitedConference";
import {
    BrowserRouter as Router,
    Route,
    Routes,
  } from "react-router-dom";
import ConferenceFeedback from './screens/ConferenceFeedback';

function AppRoutes() {
  return (
    <Router>
    <Routes>
    <Route path="/" element={
  <PrivateRoute>
    <DashBoard/>
  </PrivateRoute> 
  } />
    <Route
        path="/login"
        element={<LoginPage />}
      />
    
    <Route path="/favourites" element={
  <PrivateRoute>
    <Favourites/>
  </PrivateRoute> 
  } />
  <Route path="/editconference" element={
  <PrivateRoute>
    <EditConference/>
  </PrivateRoute> 
  } />
  <Route path="/resources" element={
  <PrivateRoute>
    <Resources/>
  </PrivateRoute> 
  } />
  <Route path="/subscriptions" element={
  <PrivateRoute>
    <Subscriptions/>
  </PrivateRoute> 
  } />
  <Route path="/search" element={
  <PrivateRoute>
    <SearchScreen/>
  </PrivateRoute> 
  } />
  <Route path="/last-joined-conferences" element={
  <PrivateRoute>
    <LastJoinedConference/>
  </PrivateRoute> 
  } />
  <Route path="/invited-conferences" element={
  <PrivateRoute>
    <InvitedConference/>
  </PrivateRoute> 
  } />
  <Route path="/conference-feedback" element={
    <ConferenceFeedback />
  } />
  </Routes>
  </Router>
  )
}

export default AppRoutes