import React from 'react';
import './index.css';
import { useNavigate } from "react-router-dom";

function SearchBar() {
  let navigate = useNavigate();
  const routeChange=()=>{
    const path = '/search'; 
    navigate(path);
  }
  return (
    <button className='search-bar' onClick={routeChange}>
            <p>
            Search for Last Joined, Invited & more
            </p>
            <i className="fa-solid fa-magnifying-glass"></i>
    </button>
  )
}

export default SearchBar