const User = require("./User");
const user = new User();

class Userprofile {
  constructor(idtoken, accesstoken, profile, user_uuid, userid) {
    this.idtoken = idtoken;
    this.accesstoken = accesstoken;
    this.profile = profile;
    this.user_uuid = user_uuid;
    this.userid = userid;
  }

  setuserprofile = (object) => {
    //console.log(object);
    this.idtoken = object.idtoken;
    this.accesstoken = object.accesstoken;
    user.setuser(object.profile);
    this.profile = user.getuser();
    this.userid = object.userid;
    this.user_uuid = object.user_uuid;
  };

  getuserprofile = () => {
    const data = {
      idtoken: this.idtoken,
      accesstoken: this.accesstoken,
      profile: this.profile,
      userid: this.userid,
      user_uuid: this.user_uuid,
    };
    //console.log(data);
    return data;
  };
}

module.exports = Userprofile;
