import "./index.css";
import CustomButton from '../CustomButton/index';

export default function Modal(props) {

  if(props.alert!=null) {
    document.body.classList.add('active-modal')
  } else {
    document.body.classList.remove('active-modal')
  }

  return (
    <>
    {/* <button className="close-modal" onClick={props.toggleModal}>
            Open
    </button> */}
      {props.alert && (
        <div className="modal">
          <div onClick={props.toggleModal} className="overlay"></div>
          <div className={`modal-content-${props.alert.type}`}>
            <div className="modal-content-inner">
              <div className="modal-content-inner-icon">
             {props.alert.type==="success" && <i className="fa-solid fa-circle-check fa-lg"/>}
             {props.alert.type==="error" && <i className="fa-sharp fa-solid fa-circle-exclamation"></i>}
              </div>
            <div className="modal-content-inner-content">
            <h2 className="modal-heading">{props.alert.heading}</h2>
            <p className="modal-content">
              {props.alert.message}
            </p>
            {props.alert.custombutton && <CustomButton />}
            </div>
            </div>
            <div>
            <button className="close-modal" onClick={props.closeModal}>
            <i className="fa-solid fa-xmark"></i>
            </button>
            </div>
            </div>
          </div>
      )}
    </>
  );
}
     