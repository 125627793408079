export default class Apihelper {
  static baseUrl = "https://devapi.hoot.mx/";
  static searchConference = "search/conferences/";

  static subEndpoint = "http://eventuisvc:8000/glayr-event";
  static limit = 5;
  static gridViewLimit = 20;

  static setbaseUrl(url) {
    this.baseUrl = url;
    return this.baseUrl;
  }

  static setLimit(limit) {
    this.limit = limit;
  }

  static getSearchConferenceURL(searchString) {
    return this.baseUrl + `v1/${this.searchConference}` + searchString;
  }

  static getFavouriteConferenceURL(uuid, from, to) {
    return (
      this.baseUrl +
      `v1/user/${uuid}/favourite_conferences?from=${from}&to=${to}`
    );
  }

  static getInvitedConferenceURL(uuid, page, isGrid = false) {
    return (
      this.baseUrl +
      `v2/user/${uuid}/invited_to_conferences?page=${page}&limit=${
        isGrid ? this.gridViewLimit : this.limit
      }`
    );
  }

  static getLastJoinedConferenceURL(uuid, page, isGrid = false) {
    return (
      this.baseUrl +
      `v2/user/${uuid}/last_joined_conferences?page=${page}&limit=${
        isGrid ? this.gridViewLimit : this.limit
      }`
    );
  }

  static getloginUrl() {
    return this.baseUrl + "v1/login";
  }

  static getSetFavouriteUrl(uuid, conferenceId) {
    return this.baseUrl + `v1/user/${uuid}/set_favourite_conference/${conferenceId}`;
  }

  static getUnSetFavouriteUrl(uuid, conferenceId) {
    return this.baseUrl + `v1/user/${uuid}/unset_favourite_conference/${conferenceId}`;
  }

  static getCheckConfUrl(confId) {
    return this.baseUrl + `v1/check_conf/${confId}`;
  }

  static getCreateConferenceUrl(confName) {
    return this.baseUrl + `v2/create_conference/${confName}`;
  }

  static getDeleteConferenceUrl(confId) {
    return this.baseUrl + `v1/conf/${confId}`;
  }

  static getAddSearchHistoryUrl(userId, value) {
    return this.baseUrl + `v1/user/${userId}/search_strings/${value}`;
  }

  static getSearchHistoryUrl(userId) {
    return this.baseUrl + `v1/user/${userId}/search_strings`;
  }

  static getCheckAccessCodeUrl() {
    return this.baseUrl + "v1/check_entry_code";
  }

  static getConferenceUsersURL(confID) {
    return this.baseUrl + `v1/conf/${confID}/all_users`;
  }

  static getEditConferenceURL(confID) {
    return this.baseUrl + `v1/edit_conference/${confID}`;
  }

  static getSIPDetailsURL(token, userID) {
    return this.baseUrl + `v1/deep_link/${token}/${userID}/sip`;
  }

  static getSubscribeConfURL(confId) {
    return this.baseUrl + `v1/conf/${confId}/subscribe`;
  }

  static getUnsubscribeConfURL(subId) {
    return this.baseUrl + `v1/subscription/${subId}`;
  }
}
