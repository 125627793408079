import React from 'react'
import Calendar from 'react-calendar';
import './index.css'


function MaterialDatePicker({date,changeDate}) {
  return (
    <div className='calendar-container'>
        <Calendar onChange={changeDate} value={date} selectRange={true} minDate={new Date()}  onClickDay={changeDate}/>
      </div>
  )
}

export default MaterialDatePicker
