import React, { useState, useEffect} from "react";
import { useSelector} from "react-redux";
import Loadingspinner from '../../../../components/LoadingProgressIndicator';
import ConferenceView from "../../../../components/ConferenceView";
import './index.css';
import { useAuth0 } from "@auth0/auth0-react";
import ConferenceServices from "../../../../services/ConferenceServices";

function ConferenceStream(props) {
  const [conferencelist, setConferencelist] = useState([]);
  const[load, setLoad] = useState(true);
  const hootconfig = useSelector((state) => state.hootConfig);
  const { isLoading,getIdTokenClaims } = useAuth0();
  // const [isFavourite, setisFavourite] = useState(false)
  setTimeout(() => setLoad(false), 5000);
  const getConferenceData = async () => {
    const res=await ConferenceServices.getConferenceData(props.invokedConference,props.userUUID,getIdTokenClaims,hootconfig);
    if(res.data!=null)
    {
    setConferencelist(res.data.reverse())
    }
  }

  useEffect(() => {
      if(isLoading)
      return;
      setTimeout(()=>{
        getConferenceData();
      },30000);
  });

  useEffect(()=>{
    getConferenceData();
  },[])


  return (
    <div className={conferencelist.length===0?"conference-stream inactive":"conference-stream"}>
    {conferencelist.length===0 && load && <Loadingspinner customClassName="loading-spinner"/>}
    {conferencelist.length===0 && !load && props.invokedConference==='lastjoined' && <p className="conference-para">You have no recent conferences</p>}
    {conferencelist.length===0 && !load && props.invokedConference!=='lastjoined' && <p className="conference-para">You have no conferences you've been invited to</p>}
      {conferencelist != null &&
        conferencelist.map((item) => (
          <ConferenceView key={item.conference.id} item={item} type={props.invokedConference==='lastjoined'?'lastjoined':'invited'} deleteFunction={props.deleteFunction} checkForFavourites={props.checkForFavourites} getConferenceUsers={props.getConferenceUsers} showInvite={props.showInvite}/>
        ))}
    </div>
  );
}

export default ConferenceStream;