// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".recent-search-button\n{\n    font-family: 'Noto Sans', sans-serif;\n    font-size: 0.63em;\n    background-color: #fff;\n    color: #646464;\n    display: flex;\n    height: 32px;\n    padding-left: 10px;\n    padding-right: 10px;\n    overflow: hidden;\n    white-space: nowrap;\n    text-overflow: ellipsis;\n    justify-content: center;\n    align-items: center;\n    border: 1.25px solid #C4C4C4;\n    border-radius: 12px;\n    min-width: 150px;\n    width: -moz-fit-content;\n    width: fit-content;\n}\n\n@media screen and (min-width: 550px){\n    .recent-search-button\n    {\n        height: 40px;\n        /* width: 170px; */\n        font-size: 0.75em;\n    }    \n}\n", "",{"version":3,"sources":["webpack://./src/screens/SearchScreen/components/RecentSearchButton/index.css"],"names":[],"mappings":"AAEA;;IAEI,oCAAoC;IACpC,iBAAiB;IACjB,sBAAsB;IACtB,cAAc;IACd,aAAa;IACb,YAAY;IACZ,kBAAkB;IAClB,mBAAmB;IACnB,gBAAgB;IAChB,mBAAmB;IACnB,uBAAuB;IACvB,uBAAuB;IACvB,mBAAmB;IACnB,4BAA4B;IAC5B,mBAAmB;IACnB,gBAAgB;IAChB,uBAAkB;IAAlB,kBAAkB;AACtB;;AAEA;IACI;;QAEI,YAAY;QACZ,kBAAkB;QAClB,iBAAiB;IACrB;AACJ","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300&display=swap');\n\n.recent-search-button\n{\n    font-family: 'Noto Sans', sans-serif;\n    font-size: 0.63em;\n    background-color: #fff;\n    color: #646464;\n    display: flex;\n    height: 32px;\n    padding-left: 10px;\n    padding-right: 10px;\n    overflow: hidden;\n    white-space: nowrap;\n    text-overflow: ellipsis;\n    justify-content: center;\n    align-items: center;\n    border: 1.25px solid #C4C4C4;\n    border-radius: 12px;\n    min-width: 150px;\n    width: fit-content;\n}\n\n@media screen and (min-width: 550px){\n    .recent-search-button\n    {\n        height: 40px;\n        /* width: 170px; */\n        font-size: 0.75em;\n    }    \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
