import Navbar from '../../components/Navbar';
import './index.css';
import LoadingOverlay from 'react-loading-overlay-ts';
import React from 'react'
import {useState,useEffect} from 'react';
import CommonSection from '../../components/CommonSection';
import VerticalDivider from '../../components/VerticalDivider';
import { useAuth0 } from "@auth0/auth0-react";
import SearchBarInput from './components/SearchBarInput';
import SessionStorage from '../../util/SessionStorage';
import ConferenceServices from '../../services/ConferenceServices'
import Modal from '../../components/AlertDialog'
import CreateConferenceModal from '../../components/CreateConferenceModal'
import JoinConferenceModal from '../../components/JoinConferenceModal'
import ConferenceModal from '../../components/ConferenceModal'
import MobileConferenceButtons from '../../components/MobileConferenceButtons'
import SearchConferenceView from './components/SearchConferenceView'
import Loadingspinner from '../../components/LoadingProgressIndicator';
import RecentSearchItems from './components/RecentSearchItems';
import { useNavigate } from "react-router-dom";
import {setConferenceAction} from "../../stateManagement/actions/index"
import { useSelector,useDispatch } from "react-redux";
import InvitePickerAdvanced from '../../components/InvitePickerAdvanced';
import PinnedConferenceModal from '../../components/PinnedConferenceModal';


function SearchScreen() {
  const [isDataLoading, setisLoading] = useState(false);
  const [searchTerm, setsearchTerm] = useState('')
  const [recentSearch, setrecentSearch] = useState([])
  const [loadingOverlay, setloadingOverlay] = useState(false)
  // const [modalOverlay, setModalOverlay] = useState(false);
  const [alert, setAlert] = useState(null);
  const [conferencelist, setconferencelist] = useState([])
  const navigate =useNavigate()
  const dispatch = useDispatch()

  const [showInvite, setshowInvite] = useState(false)
  const [inviteConference, setinviteConference] = useState(null)
  const [inviteParticipantsList, setinviteParticipantsList] = useState([])

  const [pinnedConference, setpinnedConference] = useState(false)

  const closePinnedConference=()=>{
    setpinnedConference(false)
  }

  const showPinnedConference=()=>{
    setpinnedConference(true)
  }

  // console.log('User details are: ',userDetails)
  // console.log('User details are: ',user)

  const changeShowInvite=(event)=>{
    event.preventDefault()
    setshowInvite(!showInvite)
  }

  const showInvitePicker=async(conference,event)=>{
    event.stopPropagation()
    changeIsLoading(true)
    const res=await ConferenceServices.getConferenceUsers(conference.conference.id,getIdTokenClaims,hootconfig)
    changeIsLoading(false)
    if(res.result===false)
    showAlert('error','Unable to fetch conference users data',res.message)
    else
    {
    console.log('Got conference users data',res.data)
    const confParticipants=res.data.participants===null?[]:res.data.participants
    const confModerators=res.data.moderators===null?[]:res.data.moderators
    setinviteParticipantsList([...confModerators,...confParticipants])
    setinviteConference(conference)
    setshowInvite(true)
    }
  }

  // console.log('User details are: ',userDetails)
  const getConferenceUsers=async(conference,event)=>{
    event.stopPropagation()
    changeIsLoading(true)
    const res=await ConferenceServices.getConferenceUsers(conference.conference.id,getIdTokenClaims,hootconfig)
    changeIsLoading(false)
    if(res.result===false)
    showAlert('error','Unable to fetch conference users data',res.message)
    else
    {
    console.log('Got conference users data',res.data)
    console.log('Got conference features data: ',conference.conference.features)
    const conferenceData={
      'id': conference.conference.id,
      'moderators': res.data.moderators===null?[]:res.data.moderators,
      'participants': res.data.participants===null?[]:res.data.participants,
      'name': conference.conference.name,
      'isWebCamOn': Object.keys(conference.conference.features).length === 0?false:conference.conference.features.join_webcam_on,
      'isAudioOn': Object.keys(conference.conference.features).length === 0?false:conference.conference.features.join_audio_on,
      'documents': (Object.keys(conference.conference.features).length === 0 && Object.keys(conference.conference.features.documents).length === 0 ) ?[]:conference.conference.features.documents.pdf_list,
    }
    dispatch(setConferenceAction(conferenceData))
    navigate('/editconference')
    }
  }

  const changeIsLoading=(value)=>{
    setisLoading(value);
    // console.log('Value of isloading in search screen is',isDataLoading)
  }

  const changeSearchTerm=(event)=>{
    event.preventDefault()
    setsearchTerm(event.target.value)
  }
  const [createModal, setcreateModal] = useState(false)

  const [joinModal,setJoinModal]= useState(false)

  const [isFavourite,setFavourite] = useState(false)

  const [conference,setConference] = useState(null)

  const {getIdTokenClaims,isLoading,user} = useAuth0();

  const hootconfig = useSelector((state) => state.hootConfig);

  const showAlert=(type,heading,message)=>{
    setAlert({
      message: message,
      type: type,
      heading: heading
    });
    setTimeout(()=>{
      setAlert(null)
    },1500);
  }

  const closeModal=()=>{
    setAlert(null);
  }

  // const closeModalOverlay=()=>{
  //   setModalOverlay(false)
  // }

  const closeCreateModalOverlay=()=>{
    setcreateModal(false)
  }

  const closeJoinModalOverlay=()=>{
    setJoinModal(false)
  }

  const showCreateModalOverlay=()=>{
    setcreateModal(true)
  }

  const showJoinModalOverlay=()=>{
    setJoinModal(true)
  }
  const deleteConference=async(conferenceid,event)=>{
    event.stopPropagation()
    console.log('Delete conference with id',conferenceid)
    setloadingOverlay(true)
    const res=await ConferenceServices.deleteconference(conferenceid,getIdTokenClaims,hootconfig)
    setloadingOverlay(false)
    if(res.result===true)
    {
    showAlert('success','Conference successfully deleted','You won\'t be able to view or use this conference anymore')
    // getSearchData()
  }
    else
    showAlert('error','Couldn\'t delete the conference',res.message)
  }

  const checkForFavourites = async(item,invokedConference)=>{
    setloadingOverlay(true)
    console.log('Checking for favourites')
    const res=await ConferenceServices.getFavouriteConferences(getIdTokenClaims,SessionStorage.getSessionStorageValue('user_uuid'),hootconfig)
    if (res.result===false)
    {
      showAlert('error','Cannot show conference details',res.message)
    }
    else
    {
      setFavourite(false)
      const conferences=res.conference
      if(conferences!=null)
      for(let i=0;i<conferences.length;i++)
      {
        if (conferences[i].conference.name===item.conference.name)
        {
        console.log('Found conference in favorite')
        setFavourite(true)
        }
      }
      setConference(item)
      // setinvokedConference(invokedConference)
    }
    setloadingOverlay(false)
  }

  const addSearchTerm=async(event)=>{
    event.preventDefault()
    if(event.keyCode ===13)
    {
      setloadingOverlay(true)
      const res=await ConferenceServices.addSearchTerm(SessionStorage.getSessionStorageValue('user_uuid'),searchTerm,getIdTokenClaims,hootconfig)
      setloadingOverlay(false)
      if(res.result===true)
      showAlert('success','Search term added to search history','You can now view the search term '+searchTerm+' in recent searches')
      else
      showAlert('error','Could\'nt add search term to search history',res.message)
    }
  }

  useEffect(() => {
    if (isLoading)
    return
    const getSearchHistory=async()=> {
      // console.log('Getting search results')
      setisLoading(true)
      const res=await ConferenceServices.getRecentSearchTerms(SessionStorage.getSessionStorageValue('user_uuid'),getIdTokenClaims,hootconfig)
      if(res.result===false)
      showAlert('error','Unable to fetch search history',res.message)
      else
      setrecentSearch(res.data)
      // console.log('Value of isloading is ',isDataLoading)
      setisLoading(false)
    }
    const getSearchData=async()=>{
      // console.log('Gettings search results')
      setisLoading(true)
      const res= await ConferenceServices.getSearchData(searchTerm,getIdTokenClaims,hootconfig)
      if(res.result===false)
      showAlert('error','Unable to fetch search result',res.message)
      else
      setconferencelist(res.data)
      // console.log('Value of isloading is ',isDataLoading)
      setisLoading(false)
    }
    // console.log('Current search term is ',searchTerm)
    if(searchTerm.length<=3)
    getSearchHistory()
    else
    getSearchData()
  }, [isLoading,getIdTokenClaims,hootconfig,searchTerm])

  const closeConferenceModal=()=>
  {
  console.log('Closing conference modal')
  setConference(null)
  }

  const setRecentSearchItem=(value)=>{
    setsearchTerm(value)
  }
  if (isLoading)
  return(<LoadingOverlay
    active={true}
    spinner>
      <div className="search-screen">
      </div>
    </LoadingOverlay>
    )
  else
  {
  return (
    <LoadingOverlay
    active={loadingOverlay}
    spinner>
    <div className="search-screen">
      <Navbar changeIsLoading={changeIsLoading} showAlert={showAlert} showPinnedConferences={showPinnedConference}/>
      <div className='search-content'>
      <Modal toggleModal={showAlert} alert={alert} closeModal={closeModal}/>
      {pinnedConference && <PinnedConferenceModal closeModal={closePinnedConference}/>}
      {createModal && <CreateConferenceModal closeModal={closeCreateModalOverlay} hootconfig={hootconfig} getIdTokenClaims={getIdTokenClaims} username={user.name} emailid={user.email}/>}
      {joinModal && <JoinConferenceModal closeModal={closeJoinModalOverlay} name={user.name}/>}
      {showInvite && <InvitePickerAdvanced participantList={inviteParticipantsList} userEmail={user.email} conference={inviteConference} changeShowInvite={changeShowInvite}/>}
      {conference && <ConferenceModal isFavourite={isFavourite} closeModalDisplay={closeConferenceModal} invokedConference={'invited'} item={conference} hootconfig={hootconfig} getIdTokenClaims={getIdTokenClaims} uuid={SessionStorage.getSessionStorageValue('user_uuid')}/>}
        <CommonSection showCreateModal={showCreateModalOverlay} showJoinModal={showJoinModalOverlay}/>
        <VerticalDivider/>
        <div className='search-content-right'>
          <a href="/" className={showInvite?"search-content back hide":"search-content back"}>
          <i className="fa-solid fa-arrow-left"></i><p>Back</p>
          </a>
          <SearchBarInput inputValue={searchTerm} changeValue={changeSearchTerm} addValue={addSearchTerm}/>
          {searchTerm.length<=3 && <h5 className='search-content-subheading'>Recent Searches</h5>}
          {/* {!isDataLoading && <ConferenceView/>} */}
          {!isDataLoading && searchTerm.length<=3 && <RecentSearchItems setRecentSearchItem={setRecentSearchItem} conferencelist={recentSearch}/>}
          {isDataLoading && <div className="search-loading-spinner"><Loadingspinner customClassName={"loading-spinner"}/></div>}
          {!isDataLoading && searchTerm.length>3 && conferencelist.length===0 && <p >No conference found in search result</p> }
          {!isDataLoading && searchTerm.length>3 && conferencelist.length>0 && <SearchConferenceView deleteFunction={deleteConference} checkForFavourites={checkForFavourites} conferencelist={conferencelist} getConferenceUsers={getConferenceUsers} showInvite={showInvitePicker}/>}
          <MobileConferenceButtons showCreateModalOverlay={showCreateModalOverlay} showJoinModalOverlay={showJoinModalOverlay}/>
        </div>
      </div>
    </div>
    </LoadingOverlay>
  )
  }
}

export default SearchScreen