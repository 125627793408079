import {useState,useEffect} from 'react'
import Loadingspinner from '../LoadingProgressIndicator';
import { useSelector,useDispatch } from "react-redux"
import ConferenceServices from '../../services/ConferenceServices';
import { useAuth0 } from "@auth0/auth0-react";
import { addSubscription,addConference,removeConference,removeSubscription } from '../../stateManagement/actions';
import '../ConferenceModal/index.css'

function ConferenceHeadingBar({item,invokedConference,showSnackbar}) {
    const pinnedConferences = useSelector((state) => state.pinnedConferences);
    const hootconfig = useSelector((state) => state.hootConfig);
    const subscriptions = useSelector((state) => state.subscriptions);
    const [isLoading, setisLoading] = useState(false)
    const [conferencePinned, setconferencePinned] = useState(false)
    const {getIdTokenClaims} = useAuth0();
    const dispatch = useDispatch();
    const pinConference=async (event)=>{
    event.preventDefault();
    setisLoading(true)
    const res=await ConferenceServices.addSubscription(item.conference.id,getIdTokenClaims,hootconfig)
    setisLoading(false)
    if(res.result===true)
    {
    dispatch(addSubscription(item.conference.id,res.subscriptionID,res.conferenceNumber))
    dispatch(addConference(item.conference))
    //TODO: subscribe to topic
    }
    else
    showSnackbar('error',res.message)
    }
    const unPinConference=async (event)=>{
    event.preventDefault();
    const subscriptionId=subscriptions.subscriptions[item.conference.id].subscriptionId
    console.log('Subscriptiond id is ',subscriptionId)
    if(subscriptionId)
    {
    setisLoading(true)
    const res=await ConferenceServices.deleteSubscription(subscriptionId,getIdTokenClaims,hootconfig)
    setisLoading(false)
    if(res.result===true)
    {
        dispatch(removeConference(item.conference.id))
        dispatch(removeSubscription(item.conference.id))
        setconferencePinned(false)
        //TODO: unsubscribe to topic
        showSnackbar('success','Removed conference from pinned conferences')
    }
    else
    showSnackbar('error','Failed to remove conference from pinned conferences')
    }
    }
    useEffect(() => {
        if(pinnedConferences.conferences.length>0)
        {
          for(let i=0;i<pinnedConferences.conferences.length;i++)
          {
            if (pinnedConferences.conferences[i].id===item.conference.id)
            setconferencePinned(true)
          }
        }
       }, [item.conference.id,pinnedConferences])

    
  return (
    <div className={invokedConference==='invited'?'conference-heading-box invited':hootconfig.name==="Generic"?'conference-heading-box recent':"conference-heading-box recent chiems"}>
    <h5>{item.conference.name}</h5>
    {/* {!isLoading && conferencePinned && <i className="fa-solid fa-thumbtack" onClick={unPinConference}></i>}
    {!isLoading && !conferencePinned && <i className="fa-solid fa-plus" onClick={pinConference}></i>} */}
    {isLoading && <Loadingspinner customClassName='loading-spinner conference'/>}
    </div>
  )
}

export default ConferenceHeadingBar