// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".time-zone-dropdown\r\n{\r\n    color: #101828;\r\n    font-size: 1em;\r\n    font-family: 'Inter', sans-serif;\r\n    font-weight: 400;\r\n    width: 160px;\r\n    /* height: 40px; */\r\n}\r\n\r\n.css-b62m3t-container\r\n{\r\n    height: 40px;\r\n    border: 1px solid #D0D5DD;\r\n    border-radius: 8px;\r\n}\r\n\r\n.css-t3ipsp-control:hover\r\n{\r\n    border-color: #0D986B;;\r\n}", "",{"version":3,"sources":["webpack://./src/components/TimezoneDropdown/index.css"],"names":[],"mappings":"AAGA;;IAEI,cAAc;IACd,cAAc;IACd,gCAAgC;IAChC,gBAAgB;IAChB,YAAY;IACZ,kBAAkB;AACtB;;AAEA;;IAEI,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;;IAEI,qBAAqB;AACzB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap');\r\n\r\n\r\n.time-zone-dropdown\r\n{\r\n    color: #101828;\r\n    font-size: 1em;\r\n    font-family: 'Inter', sans-serif;\r\n    font-weight: 400;\r\n    width: 160px;\r\n    /* height: 40px; */\r\n}\r\n\r\n.css-b62m3t-container\r\n{\r\n    height: 40px;\r\n    border: 1px solid #D0D5DD;\r\n    border-radius: 8px;\r\n}\r\n\r\n.css-t3ipsp-control:hover\r\n{\r\n    border-color: #0D986B;;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
