import './index.css'
import React, { useState, useEffect} from "react";
import { useSelector} from "react-redux";
import Loadingspinner from '../LoadingProgressIndicator';
import ConferenceView from "../ConferenceView";
import ConferenceServices from "../../services/ConferenceServices";
import SessionStorage from '../../util/SessionStorage';

function ConferenceGridView(props) {
    const [conferencelist, setConferencelist] = useState([]);
    const[load, setLoad] = useState(true);
    const hootconfig = useSelector((state) => state.hootConfig);
    setTimeout(() => setLoad(false), 4000);
    const getConferenceData = async () => {
      console.log('Calling conference API')
      const res=props.invokedConference==='favourite'?await ConferenceServices.getFavouriteConferences(props.getIdTokenClaims,SessionStorage.getSessionStorageValue('user_uuid'),hootconfig):await ConferenceServices.getConferenceData(props.invokedConference,SessionStorage.getSessionStorageValue('user_uuid'),props.getIdTokenClaims,hootconfig);
      if(props.invokedConference!=='favourite')
      {
        if(res.data!=null)
      setConferencelist(res.data.reverse())
      }
      else
      {
        // console.log('Got favourite data',res)
        if(res.result===true && res.conference!=null)
        setConferencelist(res.conference.reverse())
      }
    }

    useEffect(() => {
        setTimeout(()=>{
          getConferenceData();
        },30000);
    });
    
    useEffect(()=>{
      getConferenceData()
    },[])

    if(conferencelist.length===0 || load)
    return(
      <div className="conference-grid-view inactive">
        {conferencelist.length===0 && load && <Loadingspinner customClassName="loading-spinner"/>}
        {conferencelist.length===0 && !load && props.invokedConference==='favourite' && <p className="conference-para">You have no favourite conferences</p>}
        {conferencelist.length===0 && !load && props.invokedConference==='lastjoined' && <p className="conference-para">You havent joined any conference recently</p>}
        {conferencelist.length===0 && !load && props.invokedConference==='invited' && <p className="conference-para">You havent been invited to any conference</p>}
      </div>
    )
    else
    return (
      <div className="conference-grid-view">
        {conferencelist != null && 
          conferencelist.map((item) => (
            <ConferenceView key={item.conference.id} item={item} type={props.invokedConference==='lastjoined'?'lastjoined':'invited'} deleteFunction={props.deleteFunction} checkForFavourites={props.checkForFavourites} getConferenceUsers={props.getConferenceUsers} showInvite={props.showInvite}/>
          ))}
      </div>
    );
}

export default ConferenceGridView