import './index.css'
import { useSelector } from "react-redux";

function ToggleSwitch(props) {
  const config = useSelector((state) => state.hootConfig);
  return (
    <label className="switch">
      <input type="checkbox" checked={props.switchValue} onChange={props.toggleValue}>
      </input>
      <span className={config.name==="Generic"?"slider":"slider chiems"}/>
    </label>
  )
}

export default ToggleSwitch