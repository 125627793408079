import React from 'react'
import ClipboardCopy from '../ClipboardCopy';
import './index.css';
import LazyImageLoader from '../LazyImageLoader';
import { useSelector } from "react-redux";


function UserInfoBox(props) {
  const config = useSelector((state) => state.hootConfig);
  return (
    <div className={config.name==="Generic"?"info":"info secondary"}>
        <div className="user-image-row">
        {/* <img className="userimage" src={props.userpicture} alt="profile-pic"/> */}
        <LazyImageLoader height={32} imageSource={props.userpicture} alt={'profile-picture'} customClassName={"userimage"}/>
        <h2 className="infobox-heading">{props.username}</h2>
        </div>
        <div>
            <p className="info-box-subheading">Unique User Id:</p>
            <p className="info-box-content">{props.uniqueUserId}</p>
            <p className="info-box-subheading">Email Id:</p>
            <p className="info-box-content">{props.emailId}</p>
            <ClipboardCopy customClassName={'clipboard primary'} showAlert={props.showAlert} closeAlert={props.closeModal} copyValue={props.accessToken}>Access Token</ClipboardCopy>
        </div>
    </div>
  )
}

export default UserInfoBox;
