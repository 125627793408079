import { Auth0Provider } from "@auth0/auth0-react";
import React from "react";
import ReactDOM from "react-dom/client";
// import ReactDOM from "react-dom";
import configArray from "./config.json";
import App from "./App";
import { Provider } from "react-redux";
import store from "./stateManagement/store";
import reportWebVitals from "./reportWebVitals";
import './index.css';
import {PersistGate} from 'redux-persist/integration/react'
import {persistor} from './stateManagement/store'

let hootNameConfig = {};
configArray.forEach((config) => {
  if (config["name"].trim() === process.env["REACT_APP_HOOT_NAME"].trim()) {
    // console.log('Assigning hoot config');
    hootNameConfig = { ...config };
  }
});
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
    <Auth0Provider
      domain={hootNameConfig.app.auth.auth0_domain}
      clientId={hootNameConfig.app.auth.auth0_client_id}
      authorizationParams={{
        redirect_uri: window.location.origin
      }}
      useRefreshTokens={true}
      cacheLocation="localstorage"
    >
      <App config={hootNameConfig} />
    </Auth0Provider>
    </PersistGate>
  </Provider>
);
reportWebVitals();