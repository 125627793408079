import React from 'react'
import CustomButton from '../CustomButton'
import './index.css'

function MobileConferenceButtons(props) {
  return (
    <div className="mobile-conference-buttons">
        <CustomButton customClassName="primary-button conference" customFunction={props.showCreateModalOverlay}>Create a Conference</CustomButton>
        <div className="sized-box-height"></div>
        <CustomButton customClassName="join-button conference" customFunction={props.showJoinModalOverlay}>Join a Conference</CustomButton>
    </div>
  )
}

export default MobileConferenceButtons