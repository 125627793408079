import {useState} from 'react'
import ConferenceServices from '../../../services/ConferenceServices'
import '../index.css'
import Loadingspinner from '../../LoadingProgressIndicator'
import { useSelector } from "react-redux";


function SipCredentials(props) {
  const config = useSelector((state) => state.hootConfig);
  const [isSIPLoading, setisSIPLoading] = useState(false)
  const [userNameVisible, setuserNameVisible] = useState(false)
  const [passwordVisible, setpasswordVisible] = useState(false)
  const [domain, setdomain] = useState(null)
  const [protocol,setprotocol]= useState(null)
  const [userName, setuserName] = useState('')
  const [password, setpassword] = useState('')
  const showSIPCredentials=async()=>{
     const tokenarray = props.joinUrl.split("/");
     const token=tokenarray[tokenarray.length-1];
     setisSIPLoading(true)
     const res= await ConferenceServices.getSIPDetails(token,props.uuid,props.hootconfig,props.getIdTokenClaims)
     setisSIPLoading(false)
     if(res.result===false)
     {
         props.showSnackbar('error',res.message)
     }
     else
     {
         setuserName(res.username)
         setpassword(res.password)
         setdomain(res.domain)
         setprotocol(res.protocol)
         props.changeSIPCreds()
     }
  }
  const showUserNamePassword=(value)=>{
      if(value==='username')
      {
        setuserNameVisible(true)
        setpasswordVisible(false)
      }
      else
      {
        setuserNameVisible(false)
        setpasswordVisible(true)
      }

  }
  if(!props.sipCreds)
  return(
    <div className={config.name==="Generic"?'conference-form-row':"conference-form-row chiems"}>
    <h2 className="sip-heading" onClick={showSIPCredentials}>Show SIP Credentials</h2>
    {isSIPLoading && <div className="loading-spinner-box">
        <Loadingspinner customClassName="loading-spinner conference"/>
        </div>}
    </div>
  )
  else
  return (
    <div className="sip-credentials">
      <h3 className="modal-subheading">Voice Bridge</h3>
      <div className="sizedbox height"></div>
      <div className={config.name==="Generic"?'conference-form-row':"conference-form-row chiems"}>
          <p className="sip-text">{props.voiceBridge}</p>
          <h2 onClick={()=>props.copyValue(props.voiceBridge,'Voice Bridge')}>COPY</h2>
      </div>
      <hr className="horizontal-divider"></hr>
      <h3 className="modal-subheading">SIP Credentials</h3>
      <div className="sizedbox height"></div>
          {!userNameVisible && <h6 onClick={()=>showUserNamePassword('username')} className={config.name==="Generic"?"sip-subheading":"sip-subheading chiems"}>Tap to view username</h6>}
          {userNameVisible && <div className={config.name==="Generic"?'conference-form-row':"conference-form-row chiems"}>
              <p className="sip-text">{userName}</p>
              <div className="sizedbox width"/>
              <h2 onClick={()=>props.copyValue(userName,'SIP username')}>COPY</h2>
              </div>
              }
          {!passwordVisible && <h6 onClick={()=>showUserNamePassword('password')} className={config.name==="Generic"?"sip-subheading":"sip-subheading chiems"}>Tap to view password</h6>}
          {passwordVisible && <div className={config.name==="Generic"?'conference-form-row':"conference-form-row chiems"}>
              <p className="sip-text">{password}</p>
              <div className="sizedbox width"/>
              <h2 onClick={()=>props.copyValue(password,'SIP password')}>COPY</h2>
              </div>
              }
        {domain && <h3 className="modal-subheading">Domain</h3>}
        {domain && <div className="sizedbox height"></div>}
        {domain && <div className={config.name==="Generic"?'conference-form-row':"conference-form-row chiems"}>
          {domain && <p className="sip-text">{domain}</p>}
          <h2 onClick={()=>props.copyValue(domain,'Domain')}>COPY</h2>
        </div>}
        {protocol && <h3 className="modal-subheading">Protocol</h3>}
        {protocol && <div className="sizedbox height"></div>}
        {protocol && <div className='conference-form-row'>
          {protocol && <p className="sip-text">{protocol}</p>}
        </div>}
    </div>
  )
}

export default SipCredentials
