import Select from "react-select";
// import moment from "moment-timezone";
import './index.css'
import {timeZones} from '../../util/Constants'

const TimeZoneDropdown = (props) => {
  const timeZoneOptions =  timeZones.map((zone)=>({
    value: zone.value,
    label: zone.zone
  }))

  // const date = new Date();
  // const dateAsString = date.toString();
  // const timezone = dateAsString.match(/\(([^\)]+)\)$/)[1];

  // console.log(moment.tz.guess())
  return (
      <label className="time-zone-dropdown">
        {/* Time Zone: */}
        <Select
          options={timeZoneOptions}
          value={props.selectedTimeZone}
          defaultValue={{value: "GMT",zone: "GMT"}}
          // defaultValue={"GMT"}
          onChange={(timezone) => props.setSelectedTimeZone(timezone)}
          placeholder="Timezone"
        />
      </label>
  );
};

export default TimeZoneDropdown;