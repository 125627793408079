import {useState} from 'react'
import CustomButton from '../CustomButton'
import Loadingspinner from '../LoadingProgressIndicator'
import ToggleSwitch from '../ToggleSwitch'
import SipCredentials from './components/SipCredentials'
import CustomSnackbar from '../../components/Snackbar'
import './index.css'
import ConferenceServices from '../../services/ConferenceServices'
import ToggleSwitchEventHandler from '../ToggleSwitchEventHandler'
import ConferenceHeadingBar from '../ConferenceHeadingBar'
import { useSelector } from "react-redux";


function ConferenceModal(props) {
  const [snackbar, setsnackbar] = useState(false)
  const [snackbarMessage, setsnackbarMessage] = useState(null)
  const [snackbarMessageType, setsnackbarMessageType] = useState(null)
  const [isloading, setisloading] = useState(false)
  const [isFavourite, setisFavourite] = useState(props.isFavourite)
  const [sipCreds, setsipCreds] = useState(false)
  const config = useSelector((state) => state.hootConfig);
  // console.log('Invoked conference is: ',props.invokedConference)
  const toggleFavourites=async (event)=>{
    event.preventDefault()
    if(isFavourite===true)
    {
      setisloading(true)
      const res=await ConferenceServices.removeFavouriteConference(props.uuid,props.item.conference.id,props.getIdTokenClaims,props.hootconfig)
      setisloading(false)
      if(res===true)
      {
      setisFavourite(!isFavourite)
      showSnackbar('success','Conference ' +props.item.conference.name + ' has been removed from favourites')
      }
      else
      showSnackbar('error','Unable to remove conference from favourites')
    }
    else
    {
      setisloading(true)
      const res=await ConferenceServices.addFavouriteConference(props.uuid,props.item.conference.id,props.getIdTokenClaims,props.hootconfig)
      // console.log('Got response: ',res)
      setisloading(false)
      if(res===true)
      {
      setisFavourite(!isFavourite)
      showSnackbar('success','Conference ' +props.item.conference.name + ' has been added to favourites')
      }
      else
      showSnackbar('error','Unable to add conference to favourites')
    }    
  }

  const copyValue=(value,textvalue)=>{
    navigator.clipboard.writeText(value)
    showSnackbar('success',textvalue+' copied to clipboard')
  }
  const showSnackbar=(messageType,message)=>
  {
    setsnackbarMessage(message)
    setsnackbarMessageType(messageType)
    setsnackbar(true)
    setTimeout(()=>{
      setsnackbar(false)
      setsnackbarMessageType(null)
      setsnackbarMessage(null)
    },1500);
  }
  const closeModalDisplay=()=>{
    if(isloading)
    return
    props.closeModalDisplay()
  }
  const joinConference=()=>{
    window.open(props.item.party.join_url)
  }
  
  return (
    <div>
      {snackbar && <CustomSnackbar messageType={snackbarMessageType} message={snackbarMessage}/>}
      <div className='modal-wrapper' onClick={closeModalDisplay}></div>
      <div className='conference-modal'>
      <ConferenceHeadingBar item={props.item} invokedConference={props.invokedConference} showSnackbar={showSnackbar}/>
      <div className='conference-modal-column'>
      <div className='conference-form-row'>
          <h3>Add to Favourites</h3>
          {!isloading && <ToggleSwitchEventHandler switchValue={isFavourite} toggleValue={toggleFavourites}/>}
          {isloading && <div><Loadingspinner customClassName="loading-spinner conference"/></div>}
          {/* <button  onClick={addtofavs1} >
              Fav
              </button> */}
      </div>
      <div className='conference-form-row'>
      <h3>Copy Conference URL</h3>
      <i className="fa-solid fa-copy" onClick={()=>{copyValue(props.item.party.join_url,'Conference URL')}}></i>
      </div>
      <hr className="horizontal-divider"/>
      <SipCredentials sipCreds={sipCreds} copyValue={copyValue} changeSIPCreds={()=>setsipCreds(true)} joinUrl={props.item.party.join_url} uuid={props.uuid} hootconfig={props.hootconfig} getIdTokenClaims={props.getIdTokenClaims} showSnackbar={showSnackbar} voiceBridge={props.item.conference.voice_bridge}/>
      {props.item.conference.features.join_webcam_on && <hr className="horizontal-divider"/>}
      {props.item.conference.features.join_webcam_on && <h3 className="modal-subheading">Preferences</h3>}
      <div className="sizedbox height"></div>
      {props.item.conference.features.join_webcam_on &&  <div className='conference-form-row end'>
          <p className="sip-text">Join with Webcam on</p>
          <ToggleSwitch switchValue={props.item.conference.features.join_webcam_on} toggleValue={()=>{}}/>
      </div>}
      <CustomButton customClassName={props.invokedConference==='invited'?"primary-button conference invited":config.name==="Generic"?"primary-button conference lastjoined":"primary-button conference chiems"} customFunction={joinConference}>Join Conference</CustomButton>
      </div>
      </div>
    </div>
  )
}

export default ConferenceModal
