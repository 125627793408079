import React from 'react';
import { Navigate } from 'react-router';
import { useAuth0 } from "@auth0/auth0-react";
import {useEffect,useState} from 'react';
import LoginService from '../../services/LoginService';
import { useDispatch,useSelector } from "react-redux";
import LoadingOverlay from 'react-loading-overlay-ts';
import './index.css';
import SessionStorage from '../SessionStorage';

const Userprofile = require("../../dataModels/Userprofile");
const userprofile = new Userprofile();
const PrivateRoute = ({children}) => {
    const { user, isAuthenticated, isLoading,getIdTokenClaims,getAccessTokenSilently } = useAuth0();
    const [isUserLoggedIn, setUserLoggedIn] = useState(false);
    const hootconfig = useSelector((state) => state.hootConfig);
    const dispatch = useDispatch();
    // const currentUser = useSelector((state) => state.user);
    const [isDataLoading, setisDataLoading] = useState(false);
    useEffect(() => {
        setisDataLoading(true);
        if(isLoading)
        {
            console.log('Auth0 data is loading');
            return;
        }
        const user_uuid=SessionStorage.getSessionStorageValue('user_uuid');
        const instance=SessionStorage.getSessionStorageValue('instance');
        if(!isAuthenticated)
        {   
            console.log('User is not authenticated in Auth0');
            setUserLoggedIn(false);
            setisDataLoading(false);
            return;
        }
        console.log("User is authenticated in auth0");
        if (user_uuid!=null && user_uuid!=='' && instance===hootconfig.name)
        {
            console.log('User already has a UUID');
            setUserLoggedIn(true);
            setisDataLoading(false);
            return;
        }
        console.log("No uuid is present"); 
        async function checkLogin()
        {
            const loginValue= await LoginService.login(hootconfig,user,userprofile,getIdTokenClaims,getAccessTokenSilently,dispatch);
            // console.log('Got login Value',loginValue);
            setUserLoggedIn(loginValue);
            console.log('Setting isDataLoading to false');
            setisDataLoading(false);
        }
        checkLogin();
        // console.log('Value of logged in: ',isUserLoggedIn);
    }, 
    [dispatch,getAccessTokenSilently,getIdTokenClaims,hootconfig,isAuthenticated,user,isLoading])
    if (isLoading || isDataLoading) {
        // console.log("Rendering loading screen");
        return(
        <LoadingOverlay
    active={true}
    spinner>
      <div className="loading-screen">
      </div>
      </LoadingOverlay>);
    }
        // console.log("User logged in: ",isUserLoggedIn);
        return isUserLoggedIn? children: <Navigate to="/login" />;
};

export default PrivateRoute;