// const Userprofile = require("../../dataModels/Userprofile");

// const inuser = new Userprofile();

export const setUserDetails = (user) => {
  //console.log(user);
  return {
    type: "SET_USER_DETAILS",
    payload: user,
  };
};

function userReducer(user = {}, action) {
  //console.log(JSON.stringify(action));
  switch (action.type) {
    case "SET_USER_DETAILS":
      //console.log(action.payload);
      return {
        ...action.payload,
      };

    default:
      return user;
  }
}

export default userReducer;
