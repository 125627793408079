import React from 'react'
import './index.css'

function ClipboardCopy(props) {
  const successMessage=props.children + ' copied to clipboard';
  const onClickCopy=()=>{
    navigator.clipboard.writeText(props.copyValue);
    props.showAlert('success',successMessage, 'Get the value of '+props.children+' from clipboard');
  }
  return (
    <div className={props.customClassName}>
        <p className='content'>{props.children}</p>
        <i className="fa-solid fa-copy" onClick={onClickCopy}></i>
    </div>
  )
}

export default ClipboardCopy