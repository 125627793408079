import React from 'react'
import './index.css'

function ResourceListView({resourceName,uploadDate,resourceId,changeIsLoading,deleteResource}) {
  const deleteItem=(event)=>{
    event.preventDefault()
    changeIsLoading(true)
    //TODO: Invoke delete resource API here
    deleteResource(resourceId)
    changeIsLoading(false)
  }
  return (
    <div className='resource-list-view'>
        <div className="resource-title">
            <h2>{resourceName}</h2>
            <p>Uploaded on {uploadDate}</p>
        </div>
        <i className="fa-solid fa-trash" onClick={deleteItem}></i>
    </div>
  )
}

export default ResourceListView