import React from 'react'
import './index.css'

function Loadingspinner(props) {
    return (
        // <div className="spinner-container">
          <div className={props.customClassName}></div>
        // </div>
      );
}

export default Loadingspinner