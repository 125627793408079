import './index.css'
import RecentSearchButton from '../RecentSearchButton/index'

function RecentSearchItems(props) {
  return (
    <div className={props.conferencelist.length===0?"recent-search-items inactive":"recent-search-items"}>
      {props.conferencelist.length===0 && <p>No search history found</p>}
      {props.conferencelist.length>0 && props.conferencelist.map((item) => (
          <RecentSearchButton key={item} item={item} customFunction={()=>props.setRecentSearchItem(item)}>{item}</RecentSearchButton>
        ))}
    </div>
  )
}

export default RecentSearchItems
