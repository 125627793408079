export class StringManipulators
{
    static filterEmailIds=(emailIds, emailIdToRemove) =>{
        const indexToRemove = emailIds.indexOf(emailIdToRemove);
        if (indexToRemove !== -1) {
          emailIds.splice(indexToRemove, 1);
        }
        return emailIds;
      }
      static convertPdfToBase64(pdfFile) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            const base64Data = reader.result.split(',')[1];
            resolve(base64Data);
          };
          reader.onerror = (error) => {
            reject(error);
          };
          reader.readAsDataURL(pdfFile);
        });
    }
}