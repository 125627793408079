import React, { useState,useEffect } from 'react';
import './index.css';
import CustomButton from '../../components/CustomButton';

function ConferenceFeedback() {
  const [rating, setRating] = useState(0); 
  const [hoveredRating, setHoveredRating] = useState(0); 
  const [feedbackText, setfeedbackText] = useState('')

  const [confName, setconfName] = useState(null)

  const handleRating = (value) => {
    setRating(value);
  };

  const handleMouseEnter = (value) => {
    setHoveredRating(value);
  };

  const handleMouseLeave = () => {
    setHoveredRating(0);
  };

  const redirectToHome=()=>{

  }

  const saveChanges=()=>{

  }

  const onFeedbackChange=(event)=>{
    event.preventDefault()
    setfeedbackText(event.target.value)
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const confName = urlParams.get('confName');
    setconfName(confName);
  }, []);

  return (
    <div className="conference-feedback">
      <div className="conference-feedback-content">
        <h1>Rate your conference experience for: {confName}</h1>
        <div className="star-ratings">
          {[1, 2, 3, 4, 5].map((value) => (
            <span
              key={value}
              style={{
                cursor: 'pointer',
                color:
                  (hoveredRating > 0 ? hoveredRating : rating) >= value
                    ? 'gold'
                    : 'gray',
                // height: 40,
              }}
              onClick={() => handleRating(value)}
              onMouseEnter={() => handleMouseEnter(value)}
              onMouseLeave={handleMouseLeave}
            >
              <i className="fa-solid fa-star star-icon"></i>
            </span>
          ))}
        </div>
        {rating>0 && rating<5 && <textarea placeholder='Please let us know how we can improve your experience.' value={feedbackText} onChange={onFeedbackChange}/>}
        {rating===5 && <h2>Thank you for your excellent rating! We hope to maintain this level of experience for you.</h2>}
        <div className='feedback-buttons'>
          <div className='feedback-button start'>
           <CustomButton customClassName='cancel-button' customFunction={redirectToHome}>Go to Home Screen</CustomButton>
           </div>
           <div className='feedback-spacer' />
           <div className='feedback-button end'>
           <CustomButton customClassName='primary-button invite' customFunction={saveChanges} disabled={rating===0}>Save</CustomButton>
           </div>
        </div>
      </div>
    </div>
  );
}

export default ConferenceFeedback;
