export const setfavourites = (favourites) => {
    //console.log(favourites);
    return {
      type: "SET_FAVOURITES",
      payload: favourites,
    };
  };

  function favouritesReducer(favourites = [], action) {
    //console.log(JSON.stringify(action));
    switch (action.type) {
      case "SET_FAVOURITES":
        //console.log(action.payload);
        return {
          ...action.payload,
        };
  
      default:
        return favourites;
    }
  }

  export default favouritesReducer;