import { useState } from 'react';
import  LocalStorage  from '../../util/LocalStorage';
import '../../components/CustomButton/index.css';
import CustomButton from '../../components/CustomButton';
import axios from "axios";
import { useSelector } from "react-redux";
import Apihelper from '../../services/ApiHelper';
import './index.css';

const AccessCode=(props)=>{
    const [accessCode,setAccessCode]=useState('');
    const config = useSelector((state) => state.hootConfig);
    const [passwordVisible,setPasswordVisible]=useState(false);
    function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
  }
    const verifyCode=async (event)=>{
      event.preventDefault();
      if (accessCode==='')
      {
        props.showAlert('error','Access code is empty','Enter access code value. It should not be empty');
        return;
      }
        props.changeIsLoadingTrue();
        const url = Apihelper.getCheckAccessCodeUrl();
        const body = {"code": accessCode};
        await axios({method: 'post', url: url, data: body})
        .then(res => {
        if(res.status===200)
        {
          props.changeAccessCode(true);
          LocalStorage.setLocalStorageValue('accessCode',accessCode);
          LocalStorage.setLocalStorageValue('instance',config.name);
          props.changeIsLoadingFalse();
          props.showAlert('success','Access Code Validated','Your access code has been successfully validated');
        }
        })
        .catch(err => {
        console.log('Invalid access code',err);
        props.changeIsLoadingFalse();
        setAccessCode('');
        if (err.message==='Network Error')
        {
          props.showAlert('error',err.message,'Please check your internet connection');
          return;
        }
        props.showAlert('error','Access Code Validation Failed',capitalizeFirstLetter(err.response.data.reason));
        });
    };
    const changeAccessCodeValue=(event)=>{
        setAccessCode(event.target.value);
    }
    const changePasswordVisible=()=>setPasswordVisible(!passwordVisible);
    return(
      <form onSubmit={verifyCode} >
          <div className="form-input">
          <input placeholder="Enter an access code" type={passwordVisible?"text":"password"} onChange={changeAccessCodeValue} value={accessCode}  className="form-password-input">
          </input>
          <span onClick={changePasswordVisible}>
          <i className={passwordVisible?"fa-solid fa-eye":"fa-solid fa-eye-slash"}/>
          </span>
          </div>
          <CustomButton buttonType={"submit"} customClassName={"primary-button"}>Check Access Code</CustomButton>
      </form>  
    );
}

export default AccessCode;