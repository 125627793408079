import React, { useState } from "react"
import './index.css'
import TimeZoneDropdown from "../TimezoneDropdown"
import CustomButton from "../CustomButton"
import MaterialTimePicker from "../MaterialTimePicker"
import TimeManipulators from "../../util/TimeManipulators";
import MaterialDatePicker from "../DatePicker"
import { useSelector } from "react-redux";


const InvitePicker = (props) => {
  const config = useSelector((state) => state.hootConfig);
  const [selectedDate, setSelectedDate] = useState(new Date());
  // const [selectedTimeZone, setSelectedTimeZone] = useState(null);
  const changeDate=(date)=>{
    setSelectedDate(date)
  }


  const getFromTime=()=>{
    let time=TimeManipulators.getCurrentTime24HourFormat()
    time=TimeManipulators.roundToNearest30MinutesForward(time)
    return TimeManipulators.formatDateTime(time)
  }

  const getToTime=()=>{
    let time=TimeManipulators.getCurrentTime24HourFormat()
    time=TimeManipulators.roundToNearest30MinutesForward(time)
    time=TimeManipulators.add30Minutes(time)
    return TimeManipulators.formatDateTime(time)
  }

  const [fromTime, setFromTime] = useState(getFromTime());

  const [toTime, settoTime] = useState(getToTime())

  const changeFromTime=(value)=>{
    setFromTime(value)
  }

  const changeToTime=(value)=>{
    settoTime(value)
  }

  return (
    <div className="invite-picker">
      {/* <MaterialCalendar dateValue={selectedDate} changeDateValue={changeDate}/> */}
      <MaterialDatePicker date={selectedDate} changeDate={changeDate}/>
      <div className="time-content">
      <TimeZoneDropdown selectedTimeZone={props.selectedTimeZone} setSelectedTimeZone={props.changeTimeZone}></TimeZoneDropdown>
      <MaterialTimePicker timeValue={fromTime} changeTimeValue={changeFromTime}/>
      <p>-</p>
      <MaterialTimePicker timeValue={toTime} changeTimeValue={changeToTime}/>
      </div>
      <div className="invite-picker-buttons">
      <div className="invite-button start">
      <CustomButton customClassName="cancel-button" customFunction={props.changeShowInvite}>Cancel</CustomButton>
      </div>
      <div className="invite-button end">
      <CustomButton customClassName={config.name==="Generic"?"primary-button invite":"primary-button invite chiems"} customFunction={(event)=>props.setInvite(event,selectedDate,fromTime,toTime)}>Apply</CustomButton>
      </div>
      </div>
      </div>
  );
};

export default InvitePicker;
