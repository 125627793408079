const initialState={}

const conferenceReducer=(state=initialState,action)=>{
    switch(action.type)
    {
        case "SET_CONFERENCE":
            return {...action.payload}
        default:
            return state
    }
}

export default conferenceReducer;