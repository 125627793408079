import {useState} from 'react'
import { useSelector,useDispatch } from "react-redux";
import PinnedConferenceView from '../PinnedConferenceView';
import { removeConference } from '../../stateManagement/actions';
import './index.css'
import Loadingspinner from '../LoadingProgressIndicator';
import ConferenceServices from '../../services/ConferenceServices';
import { useAuth0 } from "@auth0/auth0-react";
import {removeSubscription } from '../../stateManagement/actions';
import CustomSnackbar from '../Snackbar';

function PinnedConferenceModal({closeModal}) {
  const dispatch = useDispatch()
  const pinnedConferences = useSelector((state) => state.pinnedConferences);
  const hootconfig = useSelector((state) => state.hootConfig);
  const subscriptions = useSelector((state) => state.subscriptions)
  const [isLoading, setisLoading] = useState(false)
  const [snackbar, setsnackbar] = useState(false)
  const {getIdTokenClaims} = useAuth0();
  const [snackbarMessage, setsnackbarMessage] = useState(null)
  const [snackbarMessageType, setsnackbarMessageType] = useState(null)
  const removePinnedConference=async (conference)=>{
    console.log('Got conference id in unpin conference: ',conference.id)
    const subscriptionId=subscriptions.subscriptions[conference.id].subscriptionId
    console.log('Subscriptiond id is ',subscriptionId)
    if(subscriptionId)
    {
    setisLoading(true)
    const res=await ConferenceServices.deleteSubscription(subscriptionId,getIdTokenClaims,hootconfig)
    setisLoading(false)
    if(res.result===true)
    {
        dispatch(removeConference(conference.id))
        dispatch(removeSubscription(conference.id))
        //TODO: unsubscribe to topic
        showSnackbar('success','Removed conference from pinned conferences')
    }
    else
    showSnackbar('error','Failed to remove conference from pinned conferences')
    }
    dispatch(removeConference(conference.id))
  }

  const showSnackbar=(messageType,message)=>
  {
    setsnackbarMessage(message)
    setsnackbarMessageType(messageType)
    setsnackbar(true)
    setTimeout(()=>{
      setsnackbar(false)
      setsnackbarMessageType(null)
      setsnackbarMessage(null)
    },1500);
  }

  return (
    <div>
        {snackbar && <CustomSnackbar messageType={snackbarMessageType} message={snackbarMessage}/>}
        <div className='pinned-conference-modal' onClick={closeModal}></div>
        <div className='pinned-conference-content'>
            <h1>Pinned Conferences</h1>
            {isLoading && <Loadingspinner customClassName={'loading-spinner conference'}/>}
            {!isLoading && pinnedConferences.conferences.length===0 && <h2>No Pinned Conference found</h2>}
            {!isLoading && pinnedConferences.conferences.length>0 && pinnedConferences.conferences.map((conference)=>{
                return <PinnedConferenceView conference={conference} removePinnedConference={removePinnedConference} key={conference.id}/>
            })}
        </div>
    </div>
  )
}

export default PinnedConferenceModal