import Navbar from '../../components/Navbar';
import './index.css';
import LoadingOverlay from 'react-loading-overlay-ts';
import React, { useEffect } from 'react'
import {useState} from 'react';
import { useSelector,useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import Modal from '../../components/AlertDialog'
import ToggleSwitch from '../../components/ToggleSwitch';
import CustomButton from '../../components/CustomButton';
import AutoCompleteTextArea from '../../components/AutoCompleteTextArea';
import StringValidators from '../../util/StringValidators';
import ConferenceServices from '../../services/ConferenceServices';
import { useAuth0 } from "@auth0/auth0-react";
import {setConferenceAction} from "../../stateManagement/actions/index"
import { useRef } from 'react';
import { StringManipulators } from '../../util/StringManipulators'
import PinnedConferenceModal from '../../components/PinnedConferenceModal';

function EditConference() {
  const [isDataLoading, setisLoading] = useState(false);

  const [alert, setAlert] = useState(null);


  const changeIsLoading=(value)=>{
    setisLoading(value);
  }

  const dispatch=useDispatch()

  const conference = useSelector((state) => state.conference);

  const [participantList, setparticipantList] = useState([])

  const [moderatorList, setmoderatorList] = useState([])

  const [removeParticipants, setremoveParticipants] = useState([])

  const hootconfig = useSelector((state) => state.hootConfig);

  

  const {getIdTokenClaims} = useAuth0();
  // console.log('Current conference data is: ',conference)
  const navigate=useNavigate()

  const changeParticipants=(event)=>{
    event.preventDefault()
    setparticipantList(event.target.value)
  }

  const changeModerators=(event)=>{
    event.preventDefault()
    setmoderatorList(event.target.value)
  }


  const showAlert=(type,heading,message)=>{
    setAlert({
      message: message,
      type: type,
      heading: heading
    });
    setTimeout(()=>{
      setAlert(null)
    },1500);
  }

  const closeModal=()=>{
    setAlert(null);
  }

  const [options, setoptions] = useState([...conference.moderators, ...conference.participants])
  // const [options, setoptions] = useState([])

  
  const [pinnedConference, setpinnedConference] = useState(false)

  const closePinnedConference=()=>{
    setpinnedConference(false)
  }

  const showPinnedConference=()=>{
    setpinnedConference(true)
  }


  useEffect(() => {
    console.log('Inside useeffect')
    console.log('Current conference data is: ',conference)
    if(conference===null || Object.keys(conference).length ===0)
    {
    console.log('Conference is empty')
    navigate('/')
    }
    else
    {
      // console.log()
  }
  }, [conference,navigate])

  const saveChanges=(event)=>{
    event.preventDefault()
    const features = 
    pdfValue===null?
    {
      "join_audio_on": joinAudioOn,
      "join_webcam_on": webcamOn,
      "branding_skin_color": "#97340F",
      "branding_logo_url": "https://storage.googleapis.com/hoot-mx-api/hoot_2000_1012.png",
    }:
    {
      "join_audio_on": joinAudioOn,
      "join_webcam_on": webcamOn,
      "branding_skin_color": "#97340F",
      "branding_logo_url": "https://storage.googleapis.com/hoot-mx-api/hoot_2000_1012.png",
      "documents": {
        "pdf_list": [
          pdfValue
        ]
      }
    }
    const participantarr = 
    participantList.length>0?
    participantList.replace(/\n/g, ",").split(",").map(element => {
      return element.trim();
    }):[]
    const moderatorarr = 
    moderatorList.length>0?
    moderatorList.replace(/\n/g, ",").split(",").map(element => {
      return element.trim();
    }):[]
    let flag=true
    for (let i=0;i<participantarr.length;i++)
    {
      if(participantarr[i]!=='' && !StringValidators.emailvalidation(participantarr[i]))
      {
        flag=false
        break
      }
    }
    if(flag===false)
    {
      showAlert('error','Invalid email id in participants input','Enter valid email id for participants')
      return
    }
    flag=true
    for (let i=0;i<moderatorarr.length;i++)
    {
      if(moderatorarr[i]!=='' && !StringValidators.emailvalidation(moderatorarr[i]))
      {
        flag=false
        break
      }
    }
    if(flag===false)
    {
      showAlert('error','Invalid email id in moderators input','Enter valid email id for moderators')
      return
    }
    // console.log('Moderator list is: ',moderatorarr)
    // console.log('Participant list is: ',participantarr)
    // console.log('Remove participants list is: ',removeParticipants)
    changeIsLoading(true)
    const res=ConferenceServices.editConference(conference.id,participantarr,moderatorarr,removeParticipants,features,getIdTokenClaims,hootconfig)
    changeIsLoading(false)
    if(res.result===false)
    showAlert('error','Changes couldn\'t be saved',res.message)
    else
    {
    showAlert('success','Conference edit successful','Conference changes were successfully saved')
    const conferenceData={
      'id': conference.id,
      'moderators': moderatorarr,
      'participants': participantarr,
      'name': conference.name,
      'isWebCamOn': webcamOn,
      'isAudioOn': joinAudioOn,
      'documents': pdfValue===null?[]:[pdfValue]
    }
    dispatch(setConferenceAction(conferenceData))
    setoptions([...moderatorarr,...participantarr])
    setremoveParticipants([])
    }
  }
  
  const [webcamOn, setwebcamOn] = useState(!conference.isWebCamOn?false:conference.isWebCamOn)

  const [joinAudioOn, setjoinAudioOn] = useState(!conference.isAudioOn?false:conference.isAudioOn)

  const [pdfValue, setpdfValue] = useState(!conference.documents?null:conference.documents.length===0?null:conference.documents[0])

  const fileInputRef = useRef(null);
  const handleButtonClick = () => {
    fileInputRef.current.click(); // Click the hidden file input element
  };
  const addPDFValue=async(pdfFile)=>{
    try {
      const base64Data = await StringManipulators.convertPdfToBase64(pdfFile);
      // console.log('Base64 representation of PDF:', base64Data);
      const document={
        "name": pdfFile.name,
        "base64_representation":  base64Data
      }
      setpdfValue(document)
    } catch (error) {
      console.error('Failed to convert PDF to base64:', error);
      showAlert('error','Unable to upload File','Failed to convert PDF to base64:' + error)
    }
  }
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && (file.type === 'application/pdf')) {
      // Perform file upload logic here, e.g. call a function or update state with the selected file
      // console.log('Selected file:', file);
      if (file.size <= 300 * 1024) { // Maximum size of 300 KB (300 * 1024 bytes)
        // Perform file upload logic here, e.g. call a function or update state with the selected file
        // console.log('Selected file:', file);
        addPDFValue(file);
      } else {
        console.error('File size exceeds the maximum limit of 300 KB.');
        // Handle error, e.g. display an error message
        showAlert('error','The PDF file you are trying to upload exceeds the file size limit set')
      }
    } else {
      console.error('Invalid file format. Please select a .pdf or .docx file.');
      // Handle error, e.g. display an error message
    }
  }

  const removePDFValue=(event)=>{
    event.preventDefault()
    setpdfValue(null)
  }

  return (
    <LoadingOverlay
    active={isDataLoading}
    spinner>
    <div className="edit-conference">
    <Navbar changeIsLoading={changeIsLoading} showAlert={showAlert} showPinnedConferences={showPinnedConference}/>
    <div className="edit-conference-content">
    <Modal toggleModal={showAlert} alert={alert} closeModal={closeModal}/>
    {pinnedConference && <PinnedConferenceModal closeModal={closePinnedConference}/>}
    <div className={hootconfig.name==="Generic"?"favourite-nav-row":"favourite-nav-row chiems"}>
          <a href="/">HooT.MX Home</a>
          <p> &nbsp;  &#62; &nbsp;   Edit Conference</p>
        </div>
    <h4>Edit Conference</h4>
    <form onSubmit={saveChanges} className='edit-conference-form'>
    <div className="edit-form-container">
    <input
        type="file"
        accept=".pdf,.docx"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
        />
    <h4>Name</h4>
    <div className='conference-detail-display'>
    <p>{conference.name}</p>
    </div>
    <h4>Add Participants</h4>
    <textarea className="conference-form-input box" value={participantList} onChange={changeParticipants} placeholder="Enter Email, separated by comma"></textarea>
    <h4>Add Moderators</h4>
    <textarea className="conference-form-input box" value={moderatorList} onChange={changeModerators} placeholder="Enter Email, separated by comma"></textarea>
    </div>
    <div className="edit-form-container">
    <h4 className="remove-text-heading">Remove Participants</h4>
    {/* <textarea className="conference-form-input box" value={participantList} onChange={changeParticipants} placeholder="Enter Email, separated by comma"></textarea> */}
    <AutoCompleteTextArea options={options} listValue={removeParticipants} setList={(value)=>setremoveParticipants(value)}/>
    <div className="vertical-sized-box"></div>
    <h4>Choose a Presentation PDF</h4>
        {pdfValue==null &&<div className={hootconfig.name==='Generic'?"invite-row":"invite-row chiems"}>
        <p>To upload a PDF File(max 300 Kb),</p>
        <span onClick
        ={handleButtonClick}>&nbsp;Click here</span>
        </div>}
        {
          pdfValue &&<div className="conference-form-row">
          <p className="pdf-name-style">{pdfValue.name}</p>
          <i className="fa-solid fa-xmark" onClick={removePDFValue}></i>
          </div>
        }
    <h4>Preferences</h4>
    <div className='conference-form-row'>
    <p className='conference-preference'>Allow users to listen only</p>
    <ToggleSwitch switchValue={joinAudioOn} toggleValue={()=>{setjoinAudioOn(!joinAudioOn)}}/>
    </div>
    <div className='conference-form-row end'>
    <p className='conference-preference'>Only allow users with Webcam on</p>
    <ToggleSwitch switchValue={webcamOn} toggleValue={()=>{setwebcamOn(!webcamOn)}}/>
    </div>
    <CustomButton customClassName={hootconfig.name==="Generic"?"primary-button conference":"primary-button conference chiems"} buttonType={"submit"}>Save Changes
    </CustomButton>
    </div>
    </form>
    </div>
    </div>
      </LoadingOverlay>
  );
}

export default EditConference;
