class User {
  constructor(
    email,
    email_verified,
    family_name,
    given_name,
    locale,
    name,
    nickname,
    picture,
    sub,
    updated_at,
    username,
    uuid,
    conferenceurl
  ) {
    this.email = email;
    this.email_verified = email_verified;
    this.family_name = family_name;
    this.given_name = given_name;
    this.locale = locale;
    this.name = name;
    this.nickname = nickname;
    this.picture = picture;
    this.sub = sub;
    this.updated_at = updated_at;
    this.username = username;
    this.uuid = uuid;
    this.conferenceurl = conferenceurl;
  }

  setuser = (object) => {
    this.email = object.email;
    this.email_verified = object.email_verified;
    this.family_name = object.family_name;
    this.given_name = object.given_name;
    this.locale = object.locale;
    this.name = object.name;
    this.nickname = object.nickname;
    this.picture = object.picture;
    this.sub = object.sub;
    this.updated_at = object.updated_at;
    this.username = object.username;
    this.uuid = object.uuid;
    this.conferenceurl = object.conferenceurl;
  };

  getuser = () => {
    const data = {
      email: this.email,
      email_verified: this.email_verified,
      family_name: this.family_name,
      given_name: this.given_name,
      locale: this.locale,
      name: this.name,
      nickname: this.nickname,
      picture: this.picture,
      sub: this.sub,
      updated_at: this.updated_at,
      username: this.username,
      uuid: this.uuid,
      conferenceurl: this.conferenceurl,
    };

    return data;
  };
}

module.exports = User;
