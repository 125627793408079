import Navbar from '../../components/Navbar';
import './index.css';
import LoadingOverlay from 'react-loading-overlay-ts';
import React, { useEffect } from 'react'
import {useState} from 'react';
import CommonSection from '../../components/CommonSection';
import SearchBar from './components/SearchBar';
import VerticalDivider from '../../components/VerticalDivider';
import Modal from '../../components/AlertDialog';
import ConferenceStream from './components/ConferenceStream';
import SessionStorage from '../../util/SessionStorage';
import MobileConferenceButtons from '../../components/MobileConferenceButtons';
import ConferenceServices from '../../services/ConferenceServices';
import { useAuth0 } from "@auth0/auth0-react";
import CreateConferenceModal from '../../components/CreateConferenceModal';
import JoinConferenceModal from '../../components/JoinConferenceModal';
import ConferenceModal from '../../components/ConferenceModal'
import { useNavigate } from "react-router-dom";
import {setConferenceAction} from "../../stateManagement/actions/index"
import { useSelector,useDispatch } from "react-redux";
import InvitePickerAdvanced from '../../components/InvitePickerAdvanced';
import PinnedConferenceModal from '../../components/PinnedConferenceModal';


function Dashboard() {
  const [loadingoverlay, setisLoading] = useState(false);
  const [isDataLoading, setisDataLoading] = useState(false);
  const [modalOverlay, setModalOverlay] = useState(false);
  const [createModal, setcreateModal] = useState(false)
  const [joinModal,setJoinModal]= useState(false)
  const [isFavourite,setFavourite] = useState(false)
  const [conference,setConference] = useState(null)
  const [invokedConference, setinvokedConference] = useState(null)
  const {getIdTokenClaims,isLoading,user} = useAuth0();
  
  const hootconfig = useSelector((state) => state.hootConfig);
  // const userDetails = useSelector((state) => state.user);
  const navigate =useNavigate()

  const dispatch = useDispatch()

  const [showInvite, setshowInvite] = useState(false)
  const [inviteConference, setinviteConference] = useState(null)
  const [inviteParticipantsList, setinviteParticipantsList] = useState([])

  // console.log('User details are: ',userDetails)
  // console.log('User details are: ',user)

  const [pinnedConference, setpinnedConference] = useState(false)
  const closePinnedConference=()=>{
    setpinnedConference(false)
  }

  const changeShowInvite=(event)=>{
    event.preventDefault()
    setshowInvite(!showInvite)
  }

  const showPinnedConference=()=>{
    setpinnedConference(true)
  }



  const showInvitePicker=async(conference,event)=>{
    event.stopPropagation()
    changeIsLoading(true)
    const res=await ConferenceServices.getConferenceUsers(conference.conference.id,getIdTokenClaims,hootconfig)
    changeIsLoading(false)
    if(res.result===false)
    showAlert('error','Unable to fetch conference users data',res.message)
    else
    {
    console.log('Got conference users data',res.data)
    const confParticipants=res.data.participants===null?[]:res.data.participants
    const confModerators=res.data.moderators===null?[]:res.data.moderators
    setinviteParticipantsList([...confModerators,...confParticipants])
    setinviteConference(conference)
    setshowInvite(true)
    }
  }

  const getConferenceUsers=async(conference,event)=>{
    event.stopPropagation()
    changeIsLoading(true)
    const res=await ConferenceServices.getConferenceUsers(conference.conference.id,getIdTokenClaims,hootconfig)
    changeIsLoading(false)
    if(res.result===false)
    showAlert('error','Unable to fetch conference users data',res.message)
    else
    {
    console.log('Got conference users data',res.data)
    console.log('Got conference features data: ',conference.conference.features)
    const conferenceData={
      'id': conference.conference.id,
      'moderators': res.data.moderators===null?[]:res.data.moderators,
      'participants': res.data.participants===null?[]:res.data.participants,
      'name': conference.conference.name,
      'isWebCamOn': Object.keys(conference.conference.features).length === 0?false:conference.conference.features.join_webcam_on,
      'isAudioOn': Object.keys(conference.conference.features).length === 0?false:conference.conference.features.join_audio_on,
      'documents': (Object.keys(conference.conference.features).length === 0 && Object.keys(conference.conference.features.documents).length === 0 ) ?[]:conference.conference.features.documents.pdf_list,
    }
    dispatch(setConferenceAction(conferenceData))
    navigate('editconference')
    }
  }
  const changeIsLoading=(value)=>{
    setisLoading(value);
  }
  const [alert, setAlert] = useState(null);
  const [userUUID, setuserUUID] = useState(null);
  const showModalOverlay=()=>{
    console.log('showing modal overlay')
    setModalOverlay(true)
  }
  const closeModalOverlay=()=>{
    setModalOverlay(false)
  }
  const closeCreateModalOverlay=()=>{
    setcreateModal(false)
  }
  const closeJoinModalOverlay=()=>{
    setJoinModal(false)
  }
  const showCreateModalOverlay=()=>{
    setcreateModal(true)
  }
  const showJoinModalOverlay=()=>{
    setJoinModal(true)
  }
  const showAlert=(type,heading,message)=>{
    setAlert({
      message: message,
      type: type,
      heading: heading
    });
    setTimeout(()=>{
      setAlert(null)
    },1500);
  }
  const closeModal=()=>{
    setAlert(null);
  }
  const deleteConference=async(conferenceid,event)=>{
    event.stopPropagation()
    console.log('Delete conference with id',conferenceid)
    setisLoading(true)
    const res=await ConferenceServices.deleteconference(conferenceid,getIdTokenClaims,hootconfig)
    setisLoading(false)
    if(res.result===true)
    showAlert('success','Conference successfully deleted','You won\'t be able to view or use this conference anymore')
    else
    showAlert('error','Couldn\'t delete the conference',res.message)
  }
  const checkForFavourites = async(item,invokedConference)=>{
    changeIsLoading(true)
    console.log('Checking for favourites')
    console.log('Invoked conference is ',invokedConference)
    const res=await ConferenceServices.getFavouriteConferences(getIdTokenClaims,userUUID,hootconfig)
    if (res.result===false)
    {
      showAlert('error','Cannot show conference details',res.message)
    }
    else
    {
      setFavourite(false)
      const conferences=res.conference
      if(conferences!=null)
      for(let i=0;i<conferences.length;i++)
      {
        if (conferences[i].conference.name===item.conference.name)
        {
        console.log('Found conference in favorite')
        setFavourite(true)
        }
      }
      setConference(item)
      setinvokedConference(invokedConference)
    }
  }
  useEffect(() => {
    if(isLoading)
    return
    setisDataLoading(true)
    function getUserUUID(){
    const uuid= SessionStorage.getSessionStorageValue('user_uuid')
    setuserUUID(uuid)
    // console.log('In dashboard, got user id: ',uuid)
    }
    getUserUUID()
    setisDataLoading(false)
  }, [isLoading])
  const closeConferenceModal=()=>
  {
  console.log('Closing conference modal')
  setConference(null)
  }
  if (isDataLoading || isLoading) {
    console.log("Rendering loading screen in dashboard");
    return(
    <LoadingOverlay
      active={true}
      spinner>
    <div className="loading-screen">
    </div>
    </LoadingOverlay>);
  }
  else
    return (
      <LoadingOverlay
      active={loadingoverlay}
      spinner>
    <div className="dashboard">
      <Navbar changeIsLoading={changeIsLoading} showAlert={showAlert} closeAlert={closeModal} showPinnedConferences={showPinnedConference}/>
      <div className='dashboard-content'>
      <Modal toggleModal={showAlert} alert={alert} closeModal={closeModal}/>
      {showInvite && <InvitePickerAdvanced participantList={inviteParticipantsList} userEmail={user.email} conference={inviteConference} changeShowInvite={changeShowInvite}/>}
      {createModal && <CreateConferenceModal closeModal={closeCreateModalOverlay} hootconfig={hootconfig} getIdTokenClaims={getIdTokenClaims} username={user.name} emailid={user.emailid}/>}
      {createModal && <CreateConferenceModal closeModal={closeCreateModalOverlay} hootconfig={hootconfig} getIdTokenClaims={getIdTokenClaims} username={user.name} emailid={user.email}/>}
      {joinModal && <JoinConferenceModal closeModal={closeJoinModalOverlay} name={user.name}/>}
      {conference && <ConferenceModal isFavourite={isFavourite} closeModalDisplay={closeConferenceModal} invokedConference={invokedConference} item={conference} hootconfig={hootconfig} getIdTokenClaims={getIdTokenClaims} uuid={userUUID}/>}
      {pinnedConference && <PinnedConferenceModal closeModal={closePinnedConference}/>}
      {/* {modalOverlay && <div className="modal-wrapper" onClick={closeModalOverlay}></div>} */}
        <CommonSection showCreateModal={showCreateModalOverlay} showJoinModal={showJoinModalOverlay}/>
        <VerticalDivider/>
        <div className='dashboard-content-right'>
          <SearchBar/>
          <div className='dashboard-content-row'>
          <h5 className='dashboard-content-subheading'>Recently Joined Conferences</h5>
          <a href='/last-joined-conferences' className='dashboard-links'>Show More</a>
          </div>
          {userUUID && <ConferenceStream invokedConference={'lastjoined'} userUUID={userUUID} deleteFunction={deleteConference} showAlert={showAlert} checkForFavourites={checkForFavourites} getConferenceUsers={getConferenceUsers} showInvite={showInvitePicker}/>}
          <a href='/last-joined-conferences' className='conference-stream link'>Show More</a>
          <div className='dashboard-content-row'>
          <h5 className='dashboard-content-subheading'>Invited Conferences</h5>
          <a href='/invited-conferences' className='dashboard-links'>Show More</a>
          </div>
          {userUUID && <ConferenceStream invokedConference={'invited'} userUUID={userUUID}  deleteFunction={deleteConference} showAlert={showAlert} checkForFavourites={checkForFavourites} getConferenceUsers={getConferenceUsers} showInvite={showInvitePicker}/>}
          <a href='/invited-conferences' className='conference-stream link'>Show More</a>
          <MobileConferenceButtons showCreateModalOverlay={showCreateModalOverlay} showJoinModalOverlay={showJoinModalOverlay}/>
        </div>
      </div>
    </div>
      </LoadingOverlay>
  );
}
export default Dashboard;