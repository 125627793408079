import ApiHelper from './ApiHelper';
import axios from "axios";
import { setUserDetails } from "../stateManagement/reducer/UserDetails";
import SessionStorage from '../util/SessionStorage';

export default class LoginService{
    static login = async (hootconfig,user,userprofile,getIdTokenClaims,getAccessTokenSilently,dispatch) => {
        let res=false;
        try
        {
        console.log("Getting user details from login service");
        var fulltoken = await getIdTokenClaims(); //  user idtoken
        var accesstoken = await getAccessTokenSilently(); //  user accesstoken
        // console.log("Got access token: ",accesstoken);
        // console.log("Authorization token is: ",fulltoken.__raw);
        var header = {
          Authorization: fulltoken.__raw,
          'x-HOOT-Instance': hootconfig.name,
          "Content-Type": "application/json",
          //"Access-Control-Allow-Origin":"*",
          //'Access-Control-Allow-Methods': '*',
          // "Access-Control-Allow-Headers" : "*"
        };
        //console.log(header);
        const pr = JSON.stringify(user); //  user profile
        //console.log(pr);
        const url = ApiHelper.getloginUrl();
        res = await axios
          .post(url, pr, {
            headers: header,
          })
          .then((res) => {
            console.log("Recieved response from Login API");
            //console.log(res.data);
           //console.log(fulltoken.__raw);
            const object = {
              idtoken: fulltoken.__raw,
              accesstoken: accesstoken,
              profile: user,
              user_uuid: res.data.user_uuid,
              userid: res.data.user_id,
            };
            const objecttwo = {
              profile: user,
              user_uuid: res.data.user_uuid,
              userid: res.data.user_id,
            };
           // console.log(object);
            localStorage.setItem('object',JSON.stringify(objecttwo));
            userprofile.setuserprofile(object);
            dispatch(setUserDetails(userprofile.getuserprofile()));
            //if no user_uuid then move back to the flashscreen.
            if(!res.data.user_uuid)
            {
              return false;
            }
            // console.log("Got unique user id: ",res.data.user_uuid);
            // console.log("Returning true");
            SessionStorage.setSessionStorageValue('user_uuid',res.data.user_uuid);
            SessionStorage.setSessionStorageValue('instance',hootconfig.name);
            return true;
          })
          .catch((error) => {
            //console.log("here")
           console.log("Recieved error while trying to access login API: ",error);
           return false;
          });
        }
        catch(err)
        {
          console.log('Caught error while trying to get user details from HooT: ',err);
        }
          console.log('Value if res',res);
          return res;
        };
}