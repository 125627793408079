import {useState} from 'react'
import '../CreateConferenceModal/index.css'
import CustomSnackbar from '../Snackbar'
// import Loadingspinner from '../LoadingProgressIndicator'
import CustomButton from '../CustomButton'
import { useSelector } from "react-redux";

export default function JoinConferenceModal(props) {
    const config = useSelector((state) => state.hootConfig);
    const [snackbar, setsnackbar] = useState(false)
    const [snackbarMessage, setsnackbarMessage] = useState(null)
    const [snackbarMessageType, setsnackbarMessageType] = useState(null)
    const [conferenceURL, setconferenceURL] = useState('')
    const showSnackbar=(messageType,message)=>
    {
      console.log('setting snackbar value')
      setsnackbarMessage(message)
      setsnackbarMessageType(messageType)
      setsnackbar(true)
      setTimeout(()=>{
        setsnackbar(false)
        setsnackbarMessageType(null)
        setsnackbarMessage(null)
      },1500);
    }
    const closeModalDisplay=()=>{
      props.closeModal()
    }
    const changeConferenceURL=(event)=>{
        event.preventDefault()
        setconferenceURL(event.target.value)
    }
    function isValidHttpUrl(string) {
        let url;
        try {
          url = new URL(string);
        } catch (_) {
          return false;
        }
        return url.protocol === "http:" || url.protocol === "https:";
      }
    const openURL=()=>{
        if(conferenceURL.length===0)
        {
            showSnackbar('error','Conference URL is empty')
            return
        }
        if(!isValidHttpUrl(conferenceURL))
        {
            showSnackbar('error','Enter a valid Conference URL')
            return
        }
        window.open(conferenceURL)
    }
    return (
        <div>
            {snackbar && <CustomSnackbar messageType={snackbarMessageType} message={snackbarMessage}/>}
            <div className='modal-wrapper' onClick={closeModalDisplay}></div>
            <div className='create-conference-modal'>
            <div className={config.name==="Generic"?'create-conference-heading-box secondary':"create-conference-heading-box secondary chiems"}>
            <h5>Join a Conference</h5>
            </div>
            <div className='join-conference'>
            <h4>Name</h4>
                <div className='conference-detail-display'>
                    <p>{props.name}</p>
            </div>
            <h4>Conference URL</h4>
            <textarea className="conference-form-input box join" value={conferenceURL} onChange={changeConferenceURL}></textarea>
            <CustomButton customClassName={config.name==="Generic"?"join-button conference":"join-button conference chiems"} customFunction={openURL}>
            Join
              </CustomButton>
            </div>
            </div>
        </div>
      )
}
