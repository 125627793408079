// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300;500&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".clipboard\n{\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: baseline;\n    width: 100%;\n    padding-bottom: 12px;\n}\n\n.clipboard.primary\n{\n    color: #fff;\n    font-family: 'Noto Sans', sans-serif;\n    font-weight: 500;\n    font-size: 0.75em;\n}\n\n/* .clipboard.primary.content\n{\n    color: #fff;\n    font-family: 'Noto Sans', sans-serif;\n    font-weight: 500;\n    font-size: 0.75em;\n} */\n\n.fa-copy\n{\n    height: 20px;\n    color: #fff;\n}", "",{"version":3,"sources":["webpack://./src/components/ClipboardCopy/index.css"],"names":[],"mappings":"AAEA;;IAEI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,qBAAqB;IACrB,WAAW;IACX,oBAAoB;AACxB;;AAEA;;IAEI,WAAW;IACX,oCAAoC;IACpC,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;;;;;;GAMG;;AAEH;;IAEI,YAAY;IACZ,WAAW;AACf","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300;500&display=swap');\n\n.clipboard\n{\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: baseline;\n    width: 100%;\n    padding-bottom: 12px;\n}\n\n.clipboard.primary\n{\n    color: #fff;\n    font-family: 'Noto Sans', sans-serif;\n    font-weight: 500;\n    font-size: 0.75em;\n}\n\n/* .clipboard.primary.content\n{\n    color: #fff;\n    font-family: 'Noto Sans', sans-serif;\n    font-weight: 500;\n    font-size: 0.75em;\n} */\n\n.fa-copy\n{\n    height: 20px;\n    color: #fff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
