// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".search-bar-input\n{\n    border: solid 1px #C4C4C4;\n    border-radius: 12px;\n    padding-left: 12px;\n    padding-right: 12px;\n    padding-top: 2px;\n    padding-bottom: 2px;\n    background-color: #F4F4F4;\n    border: none;\n    text-decoration: none;\n    height: 36px;\n    width: inherit;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin-bottom: 1.5rem;\n}\n\n.search-bar-input input\n{\n    /* min-width: 275px; */\n    border: none;\n    outline: none;\n    background-color: inherit;\n    flex: 3 1;\n}\n\n@media screen and (min-width: 550px){\n    .search-bar-input{\n        width: 100%;\n    }    \n}", "",{"version":3,"sources":["webpack://./src/screens/SearchScreen/components/SearchBarInput/index.css"],"names":[],"mappings":"AAEA;;IAEI,yBAAyB;IACzB,mBAAmB;IACnB,kBAAkB;IAClB,mBAAmB;IACnB,gBAAgB;IAChB,mBAAmB;IACnB,yBAAyB;IACzB,YAAY;IACZ,qBAAqB;IACrB,YAAY;IACZ,cAAc;IACd,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,qBAAqB;AACzB;;AAEA;;IAEI,sBAAsB;IACtB,YAAY;IACZ,aAAa;IACb,yBAAyB;IACzB,SAAO;AACX;;AAEA;IACI;QACI,WAAW;IACf;AACJ","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300&display=swap');\n\n.search-bar-input\n{\n    border: solid 1px #C4C4C4;\n    border-radius: 12px;\n    padding-left: 12px;\n    padding-right: 12px;\n    padding-top: 2px;\n    padding-bottom: 2px;\n    background-color: #F4F4F4;\n    border: none;\n    text-decoration: none;\n    height: 36px;\n    width: inherit;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin-bottom: 1.5rem;\n}\n\n.search-bar-input input\n{\n    /* min-width: 275px; */\n    border: none;\n    outline: none;\n    background-color: inherit;\n    flex: 3;\n}\n\n@media screen and (min-width: 550px){\n    .search-bar-input{\n        width: 100%;\n    }    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
