import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import './index.css'

function AutoCompleteTextArea(props) {
  const getselect = (_,value) => {
    // if(reason==='selectOption')
    props.setList(value);
  }
  // console.log('Options are: ',props.options)
  return (
    <Autocomplete
       multiple
      size='small'
      filterSelectedOptions
      disableClearable
       className='autocomplete'
       options={props.options}
       renderInput={(params) => (
         <TextField {...params}
        //  sx={{minHeight: 250,borderRadius: 12}}
        sx={{
          fieldset: {
            border: "1px solid grey",
            borderRadius: "12px",
          }, 
          '.MuiInputBase-input': { fontSize: '0.75em' },
        }
      }
          className='remove-textfield'
          value={props.listValue}
          //  label="Search for email id of moderators and participants"
           placeholder="Search for email id of moderators and participants" 
           />
       )}
       onChange={getselect}  
     />
  )
}

export default AutoCompleteTextArea