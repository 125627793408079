import './index.css';
import photo from "../../assets/hootlogo.png";
import chiemsLogo from "../../assets/speakerbusicon.png";
import {useState} from 'react'
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector } from "react-redux";
import UserInfoBox from '../InfoBox';
// import {useEffect} from 'react';
import SessionStorage from '../../util/SessionStorage';
import {useEffect} from 'react';
import { useLocation } from 'react-router-dom'
import LazyImageLoader from '../LazyImageLoader';
import { connect } from 'react-redux';
import notificationSound from '../../assets/notification_bell.mp3';

function Navbar(props) {
  const { isLoading,user,logout,getIdTokenClaims } = useAuth0();

  const [accessToken,setAccessToken] = useState(null);

  const [hasNewMessage, setHasNewMessage] = useState(false);

  const location = useLocation();

  const [playNotificationSound, setPlayNotificationSound] = useState(false);

  const config = useSelector((state) => state.hootConfig);
  // console.log(location.pathname)

  const logoutAndRedirect=()=>{
    props.changeIsLoading(true);
    sessionStorage.clear();
    logout({ logoutParams: { returnTo: window.location.origin } });
    props.changeIsLoading(false);
    };
  const [clicked, setclicked] = useState(false);
  const copyAccessToken=async (event)=>{
    event.preventDefault()
    try{
      const token=await getIdTokenClaims()
      navigator.clipboard.writeText(token.__raw)
      props.showAlert('success','Access token copied to clipboard','You can use the access token from your clipboard')
    }
    catch(error)
    {
    props.showAlert('error','Unable to copy access token to clipboard',error)
    }
    }
  const changeIsClicked=()=> {
    setclicked(!clicked);
  }
useEffect(() => {
  if(isLoading)
    {
      props.changeIsLoading(true);
      return;
    }
  else
  props.changeIsLoading(false);
  async function getAccessToken()
        {
            try{
            props.changeIsLoading(true);
            const token=await getIdTokenClaims();
            console.log('Got the id token')
            setAccessToken(token.__raw);
            }
            catch(err)
            {
              console.log('Error occured while trying to get access token',err);
            }
            props.changeIsLoading(false);
        }
  if(accessToken==null)
  getAccessToken();
}, [isLoading,getIdTokenClaims,props,accessToken]);


useEffect(() => {
  if (props.currentMessage) {
    // New message received
    setHasNewMessage(true);
    setPlayNotificationSound(true);

    const timeout = setTimeout(() => {
      setHasNewMessage(false);
    }, 1000);

    return () => clearTimeout(timeout);
  }
  }, [props.currentMessage]);

  useEffect(() => {
    if (playNotificationSound) {
      const audio = new Audio(notificationSound);
      audio.play();
  
      // Reset the playNotificationSound state after the sound finishes playing
      audio.addEventListener('ended', () => {
        setPlayNotificationSound(false);
      });
  
      return () => audio.removeEventListener('ended', () => {});
    }
  }, [playNotificationSound]);


  return (
      <nav className={config.name==="Generic"?"navbar-nav":"navbar-nav secondary"}>
        <a href="/"><img className={config.name==="Generic"?"headerhootlogo":"headerhootlogo secondary"} src={config.name==="Generic"?photo:chiemsLogo} alt="hoot-logo"/></a>
      <div className={clicked?config.name==="Generic"?"navbar-content active":"navbar-content active secondary":config.name==="Generic"?"navbar-content":"navbar-content secondary"}>
        {!isLoading && user && accessToken &&<UserInfoBox userpicture={user.picture} username={user.name} uniqueUserId={SessionStorage.getSessionStorageValue('user_uuid')} emailId={user.email} showAlert={props.showAlert} closeAlert={props.closeModal} accessToken={accessToken}/>}
        <ul id="navbar-links">
          <li> <a href="/" className={location.pathname==='/'?config.name==="Generic"?"active":"active-secondary":config.name==="Generic"?"inactive-link":"inactive-link secondary"}>Home</a></li>
          <li><a href="/favourites" className={location.pathname==='/favourites'?config.name==="Generic"?"active":"active-secondary":config.name==="Generic"?"inactive-link":"inactive-link secondary"}>Favourites</a></li>
          {/* <li><a href="/resources">Resources</a></li>  */}
          {/* <li><a href="/subscriptions">Subscriptions</a></li> */}
          <li><button onClick={logoutAndRedirect} className={config.name==="Generic"?"":"secondary"}>Logout</button></li>
          <li><button onClick={copyAccessToken} className='access-token-button'>Copy Access Token</button></li>
          {/* <li>{!isLoading && user && <img className="profilepicture" src={user.picture} alt="profile-pic"/>}</li> */}
          <li>{!isLoading && user && <LazyImageLoader height={32} imageSource={user.picture} imgAlt={"profile-picture"} customClassName={"profilepicture"}/>}</li>
          {/* <li><i className={`fa-solid fa-bell ${hasNewMessage} ? ' animate-bell' : ''`} onClick={props.showPinnedConferences}></i></li> */}
        </ul>
      </div>
      <div id="mobile" onClick={changeIsClicked}>
        <i id="bar" className={clicked? "fa-solid fa-times":"fa-solid fa-bars"}></i>
      {/* <i className="fa-solid fa-bars"></i>
      <i className="fa-solid fa-times"></i> */}
      </div>
      </nav>
  );
}


const mapStateToProps = (state) => ({
  currentMessage: state.fcmReducer.currentMessage,
});




export default connect(mapStateToProps)(Navbar);

