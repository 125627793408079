export const timeZones = [
    { zone: "(GMT+0:00) Greenwich Mean Time", value: "Etc/GMT" },
    { zone: "(GMT+0:00) Coordinated Universal Time", value: "Etc/UTC" },
    { zone: "(GMT+1:00) European Central Time", value: "Europe/Paris" },
    { zone: "(GMT+2:00) Eastern European Time", value: "Europe/Athens" },
    { zone: "(GMT+2:00) Egypt Standard Time", value: "Africa/Cairo" },
    { zone: "(GMT+3:00) East Africa Time", value: "Africa/Nairobi" },
    { zone: "(GMT+3:30) Iran Standard Time", value: "Asia/Tehran" },
    { zone: "(GMT+4:00) Armenia Time", value: "Asia/Yerevan" },
    { zone: "(GMT+5:00) Pakistan Standard Time", value: "Asia/Karachi" },
    { zone: "(GMT+5:30) Indian Standard Time", value: "Asia/Kolkata" },
    { zone: "(GMT+7:00) Indochina Time", value: "Asia/Ho_Chi_Minh" },
    { zone: "(GMT+8:00) China Standard Time", value: "Asia/Shanghai" },
    { zone: "(GMT+9:30) Australian Central Time", value: "Australia/Darwin" },
    { zone: "(GMT+10:00) Australian Eastern Time", value: "Australia/Sydney" },
    { zone: "(GMT+13:00) Samoa Standard Time", value: "Pacific/Apia" },
    { zone: "(GMT+12:00) New Zealand Standard Time", value: "Pacific/Auckland" },
    { zone: "(GMT-10:00) Hawaii-Aleutian Standard Time", value: "Pacific/Honolulu" },
    { zone: "(GMT-4:00) Atlantic Standard Time", value: "America/Halifax" },
    { zone: "(GMT-7:00) Pacific Standard Time", value: "America/Los_Angeles" },
    { zone: "(GMT-7:00) Mountain Standard Time", value: "America/Phoenix" },
    { zone: "(GMT-6:00) Central Standard Time", value: "America/Denver" },
    { zone: "(GMT-5:00) Eastern Standard Time", value: "America/Chicago" },
    { zone: "(GMT-4:00) Eastern Daylight Time", value: "America/New_York" },
    { zone: "(GMT-4:00) Indiana Eastern Standard Time", value: "America/Indiana/Indianapolis" },
    { zone: "(GMT-4:00) Puerto Rico and US Virgin Islands Time", value: "America/Puerto_Rico" },
    { zone: "(GMT-3:30) Newfoundland Time", value: "America/St_Johns" },
    { zone: "(GMT-3:00) Argentina Time", value: "America/Argentina/Buenos_Aires" },
    { zone: "(GMT-3:00) Brasilia Time", value: "America/Sao_Paulo" },
    { zone: "(GMT+2:00) Central Africa Time", value: "Africa/Harare" },
    { zone: "(GMT+9:00) Japan Standard Time", value: "Asia/Tokyo" },
    { zone: "(GMT+1:00) United Kingdom Time", value: "Europe/London" }
];


