const subscriptionReducer = (state = {}, action) => {
  switch (action.type) {
    case 'ADD_SUBSCRIPTION':
      const { conferenceId, subscriptionId, topicId } = action.payload;
      return {
        ...state,
        subscriptions: {
          ...state.subscriptions,
          [conferenceId]: {
            subscriptionId,
            topicId
          }
        }
      };
      case 'REMOVE_SUBSCRIPTION':
        const removedConferenceId = action.payload;
        const { [removedConferenceId]: _, ...remainingSubscriptions } = state.subscriptions;
        return {
          ...state,
          subscriptions: remainingSubscriptions
        };

    default:
      return state;
  }
};

export default subscriptionReducer;
