import DeleteButton from '../DeleteButton';
import './index.css';
import { useSelector } from "react-redux";


function ConferenceView(props) {
  const config = useSelector((state) => state.hootConfig);
  // console.log('For conf name ',props.item.conference.name,' am i moderator: ',props.item.party.is_moderator)
  return (
    <div className={props.type==='lastjoined'?'conference-view primary':'conference-view secondary'} onClick={()=>props.checkForFavourites(props.item,props.type)}> 
      <div className='conference-view row content'>
        <div className='moderator-icon'>
          {props.item.party.is_moderator && <i className={config.name==="Generic"?"fa-solid fa-user":"fa-solid fa-user chiems"}></i>}
        </div>
        <h6 className="conference-view row heading">{props.item.conference.name}</h6>
      </div>
      <div className='conference-view row icons'>
      {props.item.party.is_moderator && <i className="fa-solid fa-user-plus" onClick={(event)=>{props.showInvite(props.item,event)}}></i>}
      {props.item.party.is_moderator && <i className="fa-solid fa-pen conference" onClick={(event)=>{props.getConferenceUsers(props.item,event)}}></i>}
      {!props.item.party.is_moderator && <div className='edit-icon'></div>}
      {!props.item.party.is_moderator && <div className='edit-icon'></div>}
      <DeleteButton conferenceID={props.item.conference.id} deleteFunction={props.deleteFunction}/>
      </div>
    </div>
  )
}

export default ConferenceView;










