import React, { useState } from "react"
import '../InvitePicker/index.css'
import TimeZoneDropdown from "../TimezoneDropdown"
import CustomButton from "../CustomButton"
import MaterialTimePicker from "../MaterialTimePicker"
import TimeManipulators from "../../util/TimeManipulators";
// import { StringManipulators } from "../../util/StringManipulators"
import CustomSnackbar from '../Snackbar'
import Loadingspinner from "../LoadingProgressIndicator"
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector } from "react-redux";
import StringValidators from "../../util/StringValidators"
import ConferenceServices from "../../services/ConferenceServices"
import MaterialDatePicker from "../DatePicker"


const InvitePickerAdvanced = (props) => {
  const config = useSelector((state) => state.hootConfig);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTimeZone, setSelectedTimeZone] = useState(null);
  const [isLoading, setisLoading] = useState(false)
  const [tempParticipants, settempParticipants] = useState([])
  // const [participants, setparticipants] = useState([])
  // const options=StringManipulators.filterEmailIds(props.participantList,props.userEmail)
  let epochStart=-1
  let epochEnd=-1
  const [snackbar, setsnackbar] = useState(false)
  const [snackbarMessage, setsnackbarMessage] = useState(null)
  const [snackbarMessageType, setsnackbarMessageType] = useState(null)
  const hootconfig = useSelector((state) => state.hootConfig);
  const changeDate=(date)=>{
    setSelectedDate(date)
  }
  const {getIdTokenClaims} = useAuth0();
  const getFromTime=()=>{
    let time=TimeManipulators.getCurrentTime24HourFormat()
    time=TimeManipulators.roundToNearest30MinutesForward(time)
    return TimeManipulators.formatDateTime(time)
  }

  const getToTime=()=>{
    let time=TimeManipulators.getCurrentTime24HourFormat()
    time=TimeManipulators.roundToNearest30MinutesForward(time)
    time=TimeManipulators.add30Minutes(time)
    return TimeManipulators.formatDateTime(time)
  }

  const [fromTime, setFromTime] = useState(getFromTime());

  const [toTime, settoTime] = useState(getToTime())

  const changeFromTime=(value)=>{
    setFromTime(value)
  }

  const changeToTime=(value)=>{
    settoTime(value)
  }

  const changeTempParticipants=(event)=>{
    event.preventDefault()
    settempParticipants(event.target.value)
}

    const showSnackbar=(messageType,message)=>
    {
  // console.log('setting snackbar value')
    setsnackbarMessage(message)
    setsnackbarMessageType(messageType)
    setsnackbar(true)
    setTimeout(()=>{
        setsnackbar(false)
        setsnackbarMessageType(null)
        setsnackbarMessage(null)
    },1500);
    }

  const setInvite=async (event)=>{
    event.preventDefault()
    if(selectedDate.length===0)
    {
      showSnackbar('error','Please select a date')
    return
    }
    if(selectedTimeZone===null)
    {
    showSnackbar('error','Please select a timezone')
    return
    }
    const formattedFromTime=fromTime.hour().toString()+':'+fromTime.minute().toString()
    const formattedToTime=toTime.hour().toString()+':'+toTime.minute().toString()
    let fromCombinedTime
    let toCombinedTime
    if (!Array.isArray(selectedDate)) {
    if(TimeManipulators.isSecondTimeGreater(formattedFromTime,formattedToTime)===false)
    {
    showSnackbar('error','Meeting start time should be less than meeting end time')
    return
    }
    fromCombinedTime=TimeManipulators.combineDateTime(selectedDate,fromTime.hour().toString(),fromTime.minute().toString())
    fromCombinedTime=TimeManipulators.changeTimezone(fromCombinedTime,selectedTimeZone.value) 
    epochStart=TimeManipulators.convertToEpoch(fromCombinedTime,selectedTimeZone.value)
    toCombinedTime=TimeManipulators.combineDateTime(selectedDate,toTime.hour().toString(),toTime.minute().toString())
    toCombinedTime=TimeManipulators.changeTimezone(toCombinedTime,selectedTimeZone.value) 
    epochEnd=TimeManipulators.convertToEpoch(toCombinedTime,selectedTimeZone.value)
    }
    else
    {
      fromCombinedTime=TimeManipulators.combineDateTime(selectedDate[0],fromTime.hour().toString(),fromTime.minute().toString())
    fromCombinedTime=TimeManipulators.changeTimezone(fromCombinedTime,selectedTimeZone.value) 
    epochStart=TimeManipulators.convertToEpoch(fromCombinedTime,selectedTimeZone.value)
    toCombinedTime=TimeManipulators.combineDateTime(selectedDate[1],toTime.hour().toString(),toTime.minute().toString())
    toCombinedTime=TimeManipulators.changeTimezone(toCombinedTime,selectedTimeZone.value) 
    epochEnd=TimeManipulators.convertToEpoch(toCombinedTime,selectedTimeZone.value)
    }
    console.log('Epoch start time is: ',epochStart)
    console.log('Epoch end time is: ',epochEnd)
    const temporaryUsersList = 
    tempParticipants.length>0?
    tempParticipants.replace(/\n/g, ",").split(",").map(element => {
      return element.trim();
    }):[]
    let flag=true
    for (let i=0;i<temporaryUsersList.length;i++)
    {
      if(temporaryUsersList[i]!=='' && !StringValidators.emailvalidation(temporaryUsersList[i]))
      {
        flag=false
        break
      }
    }
    console.log("Participant array is",temporaryUsersList)
    if (flag===false)
    {
    showSnackbar('error','Enter valid email ids for temporary participants')
    return
    }
    const invitationValue={
      'epoch_start':parseInt(epochStart),
      'epoch_end': parseInt(epochEnd),
      'temporary_users': temporaryUsersList
    }
    const features={"invitation": invitationValue}
    console.log('Features is: ',features)
    setisLoading(true)
    const res=await ConferenceServices.editConference(props.conference.conference.id,[],[],[],features,getIdTokenClaims,hootconfig)
    setisLoading(false)
    if(res.result===false)
    {
      showSnackbar('error','Unable to create an invite with error: ',res.message)
    }
    else
    {
    showSnackbar('success','Invite successfully created')
    setTimeout(()=>{
      props.changeShowInvite(event)
    },1500);
    }
  }

  return (
    <>
    {snackbar && <CustomSnackbar messageType={snackbarMessageType} message={snackbarMessage}/>}
    <div className="invite-modal" onClick={props.changeShowInvite} ></div>
    <div className="invite-picker">
      {/* <Calendar onChange={changeDate} value={selectedDate} defaultActiveStartDate={newDate} calendarType={"US"} className={"calendar-inner"} minDate={newDate}
      tileClassName={"calendar-inner"}
      /> */}
      <h2 className="invite-heading">{props.conference.conference.name}</h2>
      {/* <MaterialCalendar dateValue={selectedDate} changeDateValue={changeDate}/> */}
      <MaterialDatePicker date={selectedDate} changeDate={changeDate}/>
      <div className="time-content">
      <TimeZoneDropdown selectedTimeZone={selectedTimeZone} setSelectedTimeZone={setSelectedTimeZone}></TimeZoneDropdown>
      <MaterialTimePicker timeValue={fromTime} changeTimeValue={changeFromTime}/>
      <p>-</p>
      <MaterialTimePicker timeValue={toTime} changeTimeValue={changeToTime}/>
      </div>
      <div className="invite-text-area">
      <h4 className="invite sub-heading">Invite Temporary Participants</h4>
      <div className="sized-box height"></div>
      <textarea className="conference-form-input box" value={tempParticipants} onChange={changeTempParticipants} placeholder="Enter email ids of participants, seperated by comma or next line"></textarea>
      </div>
      {/* <div className="invite-text-area">
      <h4 className="invite sub-heading">Invite Existing Participants</h4>
      <div className="sized-box height"></div>
      <AutoCompleteTextArea options={options} listValue={participants} setList={(value)=>setparticipants(value)}/>
      <div className="sized-box height spacer"></div>
      </div> */}
      <div className="invite-picker-buttons">
      <div className="invite-button start">
      <CustomButton customClassName="cancel-button" customFunction={props.changeShowInvite}>Cancel</CustomButton>
      </div>
      <div className="invite-button end">
      <CustomButton customClassName={config.name==="Generic"?"primary-button invite":"primary-button invite chiems"} customFunction={setInvite}>
        {!isLoading && "Apply"}
        {isLoading && <Loadingspinner customClassName="loading-spinner secondary"/>}
        </CustomButton>
        </div>
      </div>
      </div>
      </>
  );
};

export default InvitePickerAdvanced;
