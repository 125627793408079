import {useState,useEffect} from 'react'
import './index.css'
import { connect } from 'react-redux';


function PinnedConferenceView({conference,removePinnedConference,currentMessage}) {

  const [hasNewMessage, setHasNewMessage] = useState(false);

  useEffect(() => {
    if (currentMessage) {
      // New message received
      setHasNewMessage(true);
  
      const timeout = setTimeout(() => {
        setHasNewMessage(false);
      }, 1000);
  
      return () => clearTimeout(timeout);
    }
    }, [currentMessage]);

  return (
    <div className='pinned-conference-view'>
        <h3>{conference.name}</h3>
        <div className='pinned-conference-icons'>
        <i className="fa-solid fa-circle-minus" onClick={()=>removePinnedConference(conference)}></i>
        <i className={`fa-solid fa-bell secondary view ${hasNewMessage} ? ' animate-bell' : ''`}></i>
        </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  currentMessage: state.fcmReducer.currentMessage,
});




export default connect(mapStateToProps)(PinnedConferenceView);
