import React from 'react'
import './index.css'

function CustomSnackbar(props) {
  return (
    <div className={props.messageType==='error'?"snackbar": "snackbar success"}>
        <div className="snackbar-row">
        {props.messageType==='success' && <i className="fa-solid fa-square-check"></i>}
        {props.messageType==='error' && <i className="fa-solid fa-triangle-exclamation"></i>}
        <h1>{props.messageType.charAt(0).toUpperCase() + props.messageType.slice(1)}</h1>
        </div>
        <div className="snackbar-row">
            <div></div>
        <p>{props.message}</p>
        </div>
        
    </div>
  )
}

export default CustomSnackbar
