import './index.css'
import { useRef } from 'react';

function UploadResourceBox({noOfFiles,addDocuments}) {
  const fileInputRef = useRef(null);
  const handleButtonClick = () => {
    fileInputRef.current.click(); // Click the hidden file input element
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && (file.type === 'application/pdf')) {
      // Perform file upload logic here, e.g. call a function or update state with the selected file
      console.log('Selected file:', file);
      addDocuments(file.name)
    } else {
      console.error('Invalid file format. Please select a .pdf or .docx file.');
      // Handle error, e.g. display an error message
    }
  };
  return (
    <div className='upload-box'>
         <input
        type="file"
        accept=".pdf,.docx"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
        />
        <i className="fa-solid fa-cloud-arrow-up"></i>
        {noOfFiles<5 && <h2>
        <span onClick={handleButtonClick}>Click to upload</span>&nbsp;{5-noOfFiles} more file(s)
        </h2>}
        {noOfFiles>=5 && <h2>
          You cannot upload any more files
          </h2>}
        <p>Only PDF files are accepted</p>
        <p>(max. size 10 mb)</p>
    </div>
  )
}

export default UploadResourceBox