import Navbar from '../../components/Navbar';
import './index.css';
import LoadingOverlay from 'react-loading-overlay-ts';
import React from 'react'
import {useState} from 'react';
import ResourceListView from './components/ResourceListView';
import TimeManipulators from '../../util/TimeManipulators';
import UploadResourceBox from './components/UploadResources';
import VerticalDivider from '../../components/VerticalDivider';

function Resources() {
  const [isLoading, setisLoading] = useState(false);
  const changeIsLoading=(value)=>{
    setisLoading(value);
  }
  const [documentList, setdocumentList] = useState([
    {
      'docName': 'PDF Number One',
      'uploadedOn': '25/11/2022',
      'id': 1
    },
    {
      'docName': 'PDF Number Two',
      'uploadedOn': '12/04/2022',
      'id': 2
    },
    {
      'docName': 'PDF Number Three',
      'uploadedOn': '12/04/2022',
      'id': 3
    },
    {
      'docName': 'PDF Number Four',
      'uploadedOn': '11/04/2022',
      'id': 4
    },
  ])
  const [resourceNumber, setresourceNumber] = useState(documentList.length)
  const deleteResource=(resourceId)=>{
    let newDocumentList=documentList
    newDocumentList.splice(resourceId-1, 1)
    setdocumentList(newDocumentList)
    setresourceNumber(resourceNumber-1)
  }
  const addDocuments=(resourceName)=>{
    const newDocument={
      'docName': resourceName,
      'uploadedOn': TimeManipulators.getFormattedDate(new Date()),
      'id': resourceNumber+1
    }
    let newDocumentList=documentList
    newDocumentList.push(newDocument)
    setdocumentList(newDocumentList)
    setresourceNumber(resourceNumber+1)
  }
  return (
    <LoadingOverlay
    active={isLoading}
    spinner>
    <div className="resources">
      <Navbar changeIsLoading={changeIsLoading}/>
      <div className="resource-content">
      <div className="favourite-nav-row">
          <a href="/">HooT.MX Home</a>
          <p>  &nbsp; &#62; &nbsp; Resources</p>
      </div>
      <h2 className='resources-heading'>Manage Resources</h2>
      <div className='resource-content-mobile'>
      {documentList && <div className="resource-list">
      {documentList.map((document)=>(
        <ResourceListView resourceName={document.docName} uploadDate={document.uploadedOn} resourceId={document.id} key={document.id} deleteResource={deleteResource} changeIsLoading={changeIsLoading}/>
      ))}
      </div>}
      {documentList && documentList>0 && <div className='horizontal-divider'/>}
      {documentList.length!==5 && <UploadResourceBox noOfFiles={resourceNumber} addDocuments={addDocuments}/>}
      {documentList.length>=5 && <p>You are on a free tier, and cannot uplaod any more resources. Delete some resources to upload new ones, or upgrade your subscription plan.</p>}
      </div>
      <div className='resource-content-desktop'>
      <UploadResourceBox noOfFiles={resourceNumber} addDocuments={addDocuments}/>
      <VerticalDivider/>
      <div className="resource-list-desktop">
      <p>You've uploaded {resourceNumber} files</p>
      {documentList && <div className="resource-list">
      {documentList.map((document)=>(
        <ResourceListView resourceName={document.docName} uploadDate={document.uploadedOn} resourceId={document.id} key={document.id} deleteResource={deleteResource} changeIsLoading={changeIsLoading}/>
      ))}
      {documentList.length>=5 && <p>You are on a free tier, and cannot uplaod any more resources. Delete some resources to upload new ones, or upgrade your subscription plan.</p>}
      </div>}
      </div>
      </div>
      </div>
    </div>
      </LoadingOverlay>
  );
}

export default Resources
