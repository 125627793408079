export default class StringValidators{
    static checkvalidlength = (unique) => {
        if(unique.length>0)
         return true;
         else
         return false;
    }
    static emailvalidation = (text) => {
        var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if(text.match(mailformat))
          return true;
          else
          return false;
          }
}