export default class Apihelper {

    static baseUrl = 'https://devapi.hoot.mx/v1/';
    static searchConference = 'search/conferences/';

    static subEndpoint = 'http://eventuisvc:8000/glayr-event'

    static setbaseUrl(url)
    {
      this.baseUrl=url;
      return this.baseUrl;
    }


    static getSearchConferenceURL(searchString) {
      return this.baseUrl + this.searchConference + searchString;
    }
  
    static getFavouriteConferenceURL(uuid) {
      return this.baseUrl + 'user/' + uuid + '/favourite_conferences';
    }
  
    static getInvitedConferenceURL(uuid) {
      return this.baseUrl + 'user/' + uuid + '/invited_to_conferences';
    }
  
    static getLastJoinedConferenceURL(uuid) {
      return this.baseUrl + 'user/' + uuid +'/last_joined_conferences';
    }
  
    static getloginUrl() {
      return this.baseUrl + 'login';
    }
  
    static getSetFavouriteUrl(uuid,conferenceId) {
      return this.baseUrl + 'user/' + uuid + '/set_favourite_conference/'+ conferenceId;
    }
  
    static getUnSetFavouriteUrl(uuid,conferenceId) {
      return this.baseUrl + 'user/'+ uuid +'/unset_favourite_conference/'+ conferenceId;
    }
  
    static getCheckConfUrl(confId) {
      return this.baseUrl + 'check_conf/' + confId;
    }
  
    static getCreateConferenceUrl(confName) {
      return this.baseUrl + 'create_conference/' + confName;
    }

    static getDeleteConferenceUrl(confId) {
      return this.baseUrl + 'conf/' + confId;
    }

    static getAddSearchHistoryUrl(userId,value) {
      return this.baseUrl + 'user/' + userId +'/search_strings/' + value;
    }
  
    static getSearchHistoryUrl(userId) {
      return this.baseUrl + 'user/' + userId +'/search_strings';
    }

    static  getCheckAccessCodeUrl() {
      return this.baseUrl + 'check_entry_code';
    }

    static getConferenceUsersURL(confID) {
      return this.baseUrl + 'conf/'+confID+'/all_users';
    }

    static getEditConferenceURL(confID) {
      return this.baseUrl + 'edit_conference/'+confID;
    }

    static getSIPDetailsURL(token,userID) {
      return this.baseUrl + 'deep_link/'+token+'/'+userID+'/sip';
    }

    static getSubscribeConfURL(confId)
    {
      return this.baseUrl + `conf/${confId}/subscribe`
    }

    static getUnsubscribeConfURL(subId)
    {
      return this.baseUrl + `subscription/${subId}`
    }
  }