import './index.css';


const CustomButton=(props)=>{
    return(
        <button className={props.customClassName} onClick={props.customFunction} type={props.buttonType} disabled={props.isdisabled}>
            {props.children}
        </button>
    );
};

export default CustomButton;