import React from 'react'
import { TimeField } from '@mui/x-date-pickers/TimeField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import './index.css'

function MaterialTimePicker(props) {
  // const [value, setValue] = React.useState(dayjs('2022-04-17T15:30'));
  // console.log('value is: ',value)
  // console.log('Time value is: ',props.timeValue)
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TimeField
        //   label="Format without meridiem"
          value={props.timeValue}
          onChange={props.changeTimeValue}
          format="HH:mm"
        />
        </LocalizationProvider>
  )
}

export default MaterialTimePicker