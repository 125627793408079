import './index.css'
import ConferenceView from '../../../../components/ConferenceView'

function SearchConferenceView(props) {
  return (
    <div className={props.conferencelist.length===0?"search-conference-view inactive":"search-conference-view"}>
    {/* {props.conferencelist.length==0 && <p className="search-conference-para">No conference found</p>} */}
      {props.conferencelist.length > 0 &&
        props.conferencelist.map((item) => (
          <ConferenceView key={item.conference.id} item={item} type={'invited'} deleteFunction={props.deleteFunction} checkForFavourites={props.checkForFavourites} getConferenceUsers={props.getConferenceUsers} showInvite={props.showInvite}/>
        ))}
    </div>
  )
}

export default SearchConferenceView
