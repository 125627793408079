import dayjs from 'dayjs'
import moment from 'moment';
import "moment-timezone";

export default class TimeManipulators{
    static  getCurrentTime24HourFormat=()=> {
      const currentDate = new Date();
        let hours = currentDate.getHours();
        let minutes = currentDate.getMinutes();
        // Pad single digits with leading zero
        hours = (hours < 10 ? "0" : "") + hours;
        minutes = (minutes < 10 ? "0" : "") + minutes;      
        const time24HourFormat = `${hours}:${minutes}`;
        return time24HourFormat;
      }

    static roundToNearest30MinutesForward=(time)=>{
        // Extract hours and minutes from input time
        const [hours, minutes] = time.split(':').map(Number);
      
        // Calculate total minutes
        const totalMinutes = hours * 60 + minutes;
      
        // Calculate the nearest 30-minute window
        const roundedMinutes = Math.ceil(totalMinutes / 30) * 30;
      
        // Convert rounded minutes back to hours and minutes
        const roundedHours = Math.floor(roundedMinutes / 60);
        const roundedMinutesRemainder = roundedMinutes % 60;
      
        // Pad single digits with leading zero
        const formattedHours = (roundedHours < 10 ? "0" : "") + roundedHours;
        const formattedMinutes = (roundedMinutesRemainder < 10 ? "0" : "") + roundedMinutesRemainder;
      
        const roundedTime = `${formattedHours}:${formattedMinutes}`;
        return roundedTime;
      }

    static add30Minutes=(time) =>{
        // Extract hours and minutes from input time
        const [hours, minutes] = time.split(':').map(Number);
      
        // Calculate total minutes
        const totalMinutes = hours * 60 + minutes;
      
        // Add 30 minutes
        const updatedMinutes = totalMinutes + 30;
      
        // Convert updated minutes back to hours and minutes
        const updatedHours = Math.floor(updatedMinutes / 60);
        const updatedMinutesRemainder = updatedMinutes % 60;
      
        // Pad single digits with leading zero
        const formattedHours = (updatedHours < 10 ? "0" : "") + updatedHours;
        const formattedMinutes = (updatedMinutesRemainder < 10 ? "0" : "") + updatedMinutesRemainder;
      
        const updatedTime = `${formattedHours}:${formattedMinutes}`;
        return updatedTime;
      }

    static formatDateTime=(time)=>{
        return dayjs('2022-04-17T'+time);
      } 

      static extractDateStringFromDate=(date)=>{
        // Get day, month, and year components from the date object
        const day = date.toLocaleString('en-US', { day: '2-digit' });
        const month = date.toLocaleString('en-US', { month: 'short' });
        const year = date.toLocaleString('en-US', { year: 'numeric' });
        // Construct the formatted date string in the desired format
        const dateString = `${month} ${day} ${year}`;
        return dateString;
      }
    
      static isSecondTimeGreater=(firstTime, secondTime) =>{
        // Parse the input time strings to get the hours and minutes components
        const [firstHours, firstMinutes] = firstTime.split(':').map(Number);
        const [secondHours, secondMinutes] = secondTime.split(':').map(Number);
      
        // Compare the hours and minutes components to determine if the second time is greater
        if (secondHours > firstHours) {
          return true;
        } else if (secondHours === firstHours) {
          return secondMinutes > firstMinutes;
        } else {
          return false;
        }
      }

      static getFormattedDate(date) {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
      }

      // static convertToEpoch(dateTime, timeZoneValue) {
      //   const momentDateTime = moment.tz(dateTime, timeZoneValue);
      //   return momentDateTime.valueOf();
      // }

      static convertToEpoch(dateTime, timeZoneValue) {
        const momentDateTime = moment.tz(dateTime, timeZoneValue);
        const milliseconds = momentDateTime.valueOf();
        const seconds = Math.floor(milliseconds / 1000);
        return seconds;
      }

      static combineDateTime(date, inputHour,inputMinute) {
        const combinedDateTime = new Date(date);
        // console.log('Hour is: ',inputHour)
        // console.log('Minute is: ',inputMinute)
        // console.log('Date time value is: ',combinedDateTime)
        combinedDateTime.setHours(parseInt(inputHour));
        combinedDateTime.setMinutes(parseInt(inputMinute));
        combinedDateTime.setSeconds(0);
        return combinedDateTime;
      }

      static toDateTimeObject(dayjsObject) //convert a dayjs object to its corresponding date time value
      {
        const year = dayjsObject.year();
        const month = dayjsObject.month();
        const day = dayjsObject.date();
        const hour = dayjsObject.hour();
        const minute = dayjsObject.minute();
        const second = dayjsObject.second();
        const dateTimeObject = new Date(year, month, day, hour, minute, second);
        return dateTimeObject;
      }

      static changeTimezone(date, ianatz) {

        // suppose the date is 12:00 UTC
        var invdate = new Date(date.toLocaleString('en-US', {
          timeZone: ianatz
        }));
        // then invdate will be 07:00 in Toronto
        // and the diff is 5 hours
        var diff = date.getTime() - invdate.getTime();
        // so 12:00 in Toronto is 17:00 UTC
        return new Date(date.getTime() + diff); // needs to substract
      }
}