import './index.css';
import CustomButton from '../CustomButton';
import React from 'react'
import { useSelector } from "react-redux";

function CommonSection(props) {
  const config = useSelector((state) => state.hootConfig);
  return (
    <div className='common-section'>
          <h1 className='common-section heading'>
          Hosting or Joining a Conference is just a click away
          </h1>
          <p className='common-section content'>
          Chat, call, and share video simply from one place that helps you stay close to all the people in your life. With shared documents and files always available, you can create, share, and exchange ideas whenever you want to and keep things moving forward together. Join the Conference anytime, the conference stays active as long as you want it to, helping you to keep track of all your activities.
          </p>
          <div className='common-section buttons'>
          <CustomButton customClassName={config.name==='Generic'?"primary-button":"primary-button chiems"} customFunction={props.showCreateModal}> Create a Conference
          </CustomButton>
          <div className="common-section spacer">
          </div>
          <CustomButton customClassName={config.name==='Generic'?"join-button":"join-button chiems"} customFunction={props.showJoinModal}>Join a Conference</CustomButton>
          </div>
        </div>
  )
}


export default CommonSection;